import React from "react";
import { Component } from "react";
import { PortfolioMetricsContainer } from '../../containers';
import AnalysisFiveContract from './AnalysisFiveContract'
class Dashboard extends Component {
  render() {
    return (
      <>
        <PortfolioMetricsContainer />
        <AnalysisFiveContract />
      </>
    )
  }
}
export default Dashboard;