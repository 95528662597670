
import React, { Component } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { monthNumber_to_Name } from '../../utils/helper'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

class FilterControlDateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRanges: new Map(),
            isShowCalendar_signDate: false,
            isShowCalendar_startDate: false,
            isShowCalendar_endDate: false,
        }
    }
    //pass the selected date to filtercontrol->....->Accountleveldetails
    handleChange = (range, name) => {
        //console.log(range)
        //console.log(name)
        const _this = this;
        //console.log('Catching date change:' + JSON.stringify(range) + '--' + name)
        this.setState(prevState => ({ dateRanges: prevState.dateRanges.set(name, range) }),
            () => {
                if (_this.props.onChange) {
                    console.log('Calling date change up');
                    //console.log(this.state.dateRanges)
                    _this.props.onChange(this.state.dateRanges);
                }
            })
    }
    //format date to Month Date, Year
    formatDate = (date) => {
       // console.log(date)
        return date !== null ? monthNumber_to_Name(date.getMonth()) + ' ' + date.getDate() + ', ' + date.getFullYear()
            : null;
    }

    //reuable date object

    //date UI on click on input field
    FilterControlDateRangeUI = () => {
        const classes = useStyles();
        const { isShowCalendar_signDate, isShowCalendar_startDate, isShowCalendar_endDate } = this.state;
        const [anchorEl_startDate, setAnchorEl_startDate] = React.useState(null);
        const [anchorEl_signDate, setAnchorEl_signDate] = React.useState(null);
        const [anchorEl_endDate, setAnchorEl_endDate] = React.useState(null);

        const [selectedDate, setSelectedDate] = useState({
            contractSignDate: {
                startDate: null,//new Date(),
                endDate: null,//addDays(new Date(), 7),
                color: "#156099",
                key: 'contractSignDate'
            },
            contractStartDate: {
                startDate: null,//new Date(),
                endDate: null,//addDays(new Date(), 7),
                color: "#156099",
                key: 'contractStartDate'
            },
            contractEndDate: {
                startDate: null,//new Date(),
                endDate: null,//addDays(new Date(), 7),
                color: "#156099",
                key: 'contractEndDate',
            },
        });
        //sign date
        const handleClick_signDate = event => {
            this.setState({
                isShowCalendar_signDate: true,
            });
            setAnchorEl_signDate(event.currentTarget);
        };
        const handleClose_signDate = () => {
            this.setState({
                isShowCalendar_signDate: false,
            });
            this.handleChange(selectedDate.contractSignDate, "Contract Sign Date")
            console.log(selectedDate.contractSignDate)
            setAnchorEl_signDate(null);
        };
        //start Date
        const handleClick_startDate = event => {
            this.setState({
                isShowCalendar_startDate: true,
            });
            setAnchorEl_startDate(event.currentTarget);
        };
        const handleClose_startDate = () => {
            this.setState({
                isShowCalendar_startDate: false
            });
            //this.props.onChange(this.state.dateRanges)
            setAnchorEl_startDate(null);
        };
        //end date
        const handleClick_endDate = event => {
            this.setState({
                isShowCalendar_endDate: true
            });
            setAnchorEl_endDate(event.currentTarget);
        };
        const handleClose_endDate = () => {
            this.setState({
                isShowCalendar_endDate: false
            });
            setAnchorEl_endDate(null);
        };
        const open_startDate = Boolean(setAnchorEl_startDate);
        const id_startDate = open_startDate ? 'simple-popover' : undefined;

        const open_signDate = Boolean(setAnchorEl_signDate);
        const id_signDate = open_signDate ? 'simple-popover' : undefined;

        const open_endDate = Boolean(setAnchorEl_endDate);
        const id_endDate = open_endDate ? 'simple-popover' : undefined;

        console.log(selectedDate)
        return (
            <>
                {/* Contract Sign Date*/}
                <h6 className={classes.cardTitle}>Sign Date</h6>
                <TextField
                    type="text"
                    label="Start - End"
                    id="outlined-margin-dense"
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%" }}
                    readOnly
                    onClick={handleClick_signDate}
                    value={
                        selectedDate.contractSignDate["startDate"] !== null ?
                            this.formatDate(selectedDate.contractSignDate["startDate"]) + " - " +
                            this.formatDate(selectedDate.contractSignDate["endDate"]) : "Please Select Date"
                    }
                />
                
                {isShowCalendar_signDate !== false ?
                    <Popover
                        id={id_signDate}
                        open={open_signDate}
                        anchorEl={anchorEl_signDate}
                        onClose={handleClose_signDate}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography className={classes.typography}>
                            <DateRangePicker
                                format="DD-MM-YYYY"
                                //showSelectionPreview={true}
                                //moveRangeOnFirstSelection={false}
                                minDate={new Date('1/1/2015')}
                                maxDate={new Date('1/1/2030')}
                                scroll={{ enabled: true }}
                                startDatePlaceholder="Start Date"
                                endDatePlaceholder="End Date"
                                onChange={(range) => {
                                    //console.log(range)
                                    //this.handleChange(selectedDate.contractSignDate, "Contract Sign Date")
                                    //console.log(selectedDate.contractSignDate)
                                    return setSelectedDate({ ...selectedDate, ...range })
                                }}
                                months={2}
                                editableDateInputs={true}
                                ranges={[selectedDate.contractSignDate]}
                                direction="horizontal"
                            />
                        </Typography>
                    </Popover>
                    : null}

                {/* Contract Start Date*/}

                <h6 className={classes.cardTitle}>Start Date</h6>
                <TextField
                    type="text"
                    label="Start - End"
                    id="outlined-margin-dense"
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    readOnly
                    style={{ width: "100%" }}
                    onClick={handleClick_startDate}
                    value={

                        selectedDate.contractStartDate["startDate"] !== null ?
                            this.formatDate(selectedDate.contractStartDate["startDate"]) + " - " +
                            this.formatDate(selectedDate.contractStartDate["endDate"]) : "Please Select Date"
                    }
                />
               
                {isShowCalendar_startDate !== false ?
                    <Popover
                        id={id_startDate}
                        open={open_startDate}
                        anchorEl={anchorEl_startDate}
                        onClose={handleClose_startDate}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography className={classes.typography}>
                            <DateRangePicker
                                format="DD-MM-YYYY"
                                //showSelectionPreview={true}
                                //moveRangeOnFirstSelection={false}
                                minDate={new Date('1/1/2015')}
                                maxDate={new Date('1/1/2030')}
                                scroll={{ enabled: true }}
                                startDatePlaceholder="Start Date"
                                endDatePlaceholder="End Date"
                                onChange={(range) => {
                                    this.handleChange(selectedDate.contractStartDate, "Contract Start Date")
                                    return setSelectedDate({ ...selectedDate, ...range })
                                }}
                                months={2}
                                editableDateInputs={true}
                                ranges={[selectedDate.contractStartDate]}
                                direction="horizontal"
                            />
                        </Typography>
                    </Popover>
                    : null}
                {/* Contract End Date*/}
                <h6 className={classes.cardTitle}>End Date</h6>
                <TextField
                    type="text"
                    label="Start - End"
                    id="outlined-margin-dense"
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    readOnly
                    style={{ width: "100%" }}
                    onClick={handleClick_endDate}
                    value={
                        selectedDate.contractEndDate["startDate"] !== null ?
                            this.formatDate(selectedDate.contractEndDate["startDate"]) + " - " +
                            this.formatDate(selectedDate.contractEndDate["endDate"]) : "Please Select Date"
                    }
                />
                {isShowCalendar_endDate !== false ?
                    <Popover
                        id={id_endDate}
                        open={open_endDate}
                        anchorEl={anchorEl_endDate}
                        onClose={handleClose_endDate}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography className={classes.typography}>
                            <DateRangePicker
                                format="DD-MM-YYYY"
                                //showSelectionPreview={true}
                                //moveRangeOnFirstSelection={false}
                                minDate={new Date('1/1/2015')}
                                maxDate={new Date('1/1/2030')}
                                scroll={{ enabled: true }}
                                startDatePlaceholder="Start Date"
                                endDatePlaceholder="End Date"
                                onChange={(range) => {
                                    console.log(range)
                                    this.handleChange(selectedDate.contractEndDate, "Contract End Date")
                                    return setSelectedDate({ ...selectedDate, ...range })
                                }}
                                months={2}
                                editableDateInputs={true}
                                ranges={[selectedDate.contractEndDate]}
                                direction="horizontal"
                            />
                        </Typography>
                    </Popover>
                    : null}
            </>
        )
    }
    render() {
        return <this.FilterControlDateRangeUI />
    }
}
export default FilterControlDateRange;