import React, { Component } from 'react';
import AccountCustomerDetails from '../../containers/AccountLevelData/AccountCustomerDetails'
import AccountDataContainer from '../../containers/AccountLevelData/AccountDataContainer.js'
//request to fetch data from API and display it on income page

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);
class AccountLevelDetails extends Component {

    AccountLevelDetailsUI = () => {
        const classes = useStyles();
        return (
            <>
                {/*ISO and EDCs Level details  */}
                {
                    <GridContainer>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <RemoveRedEyeIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Forward Gross Margin By ISO with EDC and Account Level Detail
                        </h4>
                            </CardHeader>
                            <CardBody>
                                <AccountDataContainer />
                            </CardBody>
                        </Card>
                    </GridContainer>
                }
                {/*customer level details */}
                {
                    <GridContainer>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <RemoveRedEyeIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                Volume By ISO/EDC
                        </h4>
                            </CardHeader>
                            <CardBody>
                                <AccountCustomerDetails />
                            </CardBody>
                        </Card>
                    </GridContainer>
                }
            </>
        )
    }
    render() {
        return (
            <>
                <this.AccountLevelDetailsUI />
            </>
        )
    }
}
export default AccountLevelDetails;
