/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import ProtectedLayout from "components/ProtectedLayout/ProtectedLayout.js";
import { Authenticator } from 'aws-amplify-react';
import AdminLayout from 'layouts/Admin.js';

import Login from './views/Login/Login.js'
import { Auth } from "aws-amplify";
import { ApolloProvider } from 'react-apollo';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';

const hist = createBrowserHistory();
const url = 'https://wdrk5i5vwbafrdrktoywd34h2y.appsync-api.us-east-2.amazonaws.com/graphql';
const region = 'us-east-2';
const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink(url, httpLink)
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const App = () => (
  <>
    <Router history={hist}>
      <Switch>
        <Route path="/admin"
          render={() =>
            <ApolloProvider client={client}>
              <Authenticator hideDefault={true}>
                <Login />
                <ProtectedLayout component={AdminLayout} />
              </Authenticator>
            </ApolloProvider>}
        />
        <Redirect from="/" to="/admin/income-statement" />
      </Switch>
    </Router>
  </>
);

export default App;