//dashboard component
import Dashboard from "views/Dashboard/Dashboard.js";
//import AnalysisFiveContract from 'views/Dashboard/AnalysisFiveContract.js';
import AccountLevelDetails from 'views/Operations/AccountLevelDetails';
import ForwardGrossMarginOverTime from 'views/Financials/ForwardGrossMarginOverTime';
import IncomeStatementView from 'views/Financials/IncomeStatementView';
//risk analysis component
/*import RiskAnalysis_Summary from 'views/RiskAnalysis/RiskAnalysis_Summary.js';
import CashMonthNOP from 'views/RiskAnalysis/CashMonthNOP'
import PNLMTM from 'views/RiskAnalysis/PNLMTM'
import PromptMonth_NOP from 'views/RiskAnalysis/PromptMonth_NOP'
import TermNOP from 'views/RiskAnalysis/TermNOP'*/

//import TimelineIcon from '@material-ui/icons/Timeline';
import DashboardIcon from "@material-ui/icons/Dashboard";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
//import WarningIcon from '@material-ui/icons/Warning';
//import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DetailsIcon from '@material-ui/icons/Details';
import ForwardIcon from '@material-ui/icons/Forward';
var dashRoutes = [
  /* {
     collapse: true,
     name: "Dashboard",
     icon: DashboardIcon,
     state: "pageCollapse",
     views: [
       {
         path: "/dashboard-overview",
         name: "Overview",
         icon: RemoveRedEyeIcon,
         //   mini: "IS",
         component: Dashboard,
         layout: "/admin"
       },
       {
         path: "/analysisfivecontract",
         name: "Analysis of 5 Contracts",
         icon: TimelineIcon,
         component: AnalysisFiveContract,
         layout: "/admin"
       },
     ]
   },*/
  {
    name: "Dashboard",
    icon: DashboardIcon,
    path: "/income-statement",
    component: IncomeStatementView,
    layout: "/admin"
  }
];
export default dashRoutes;
