import React from 'react';
import ReactDOM from 'react-dom';
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import config from "./awsconfig.js";

Amplify.configure({
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  identityPoolId: config.cognito.IDENTITY_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  aws_appsync_graphqlEndpoint: 'https://wdrk5i5vwbafrdrktoywd34h2y.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
