var selectedClient = window.localStorage.getItem(`defaultUser`);
var selectedDate = window.localStorage.getItem(`defaultDate`)

//window.localStorage.setItem('selectedClientInceom', selectedClient)
//window.localStorage.setItem('selectedDateInceom', selectedDate)

//console.log(selectedClient)
//console.log(window.localStorage.getItem(`defaultUser`))
//console.log(selectedDate)

export const IncomeStatementQuery = `query getIncomeStatement {
	getIncomeStatement(client:"${selectedClient}", id: "${selectedDate}") {
  	    iso
		type
        subType
		component
        values {
    	    year
    	    values {
      	        month
      	        value
    	    }
  	    }
    }
}`;

export const FileDownloadIncomeStatement = `query getFileDownload($filetype: String!) {
    getFileDownload(client:"${selectedClient}", id: "${selectedDate}", filetype: $filetype)
}
`