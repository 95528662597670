import { isoColor, ListMonth, capitalize } from '../../utils/helper';

export default function RearrangeDataGrossMarginVolume(get_GraphQL_GrossMargin_Data, yearLists) {
    //console.log(get_GraphQL_GrossMargin_Data)
    var isoTotal_Month_Year_KeyValuePair = [];
    if (get_GraphQL_GrossMargin_Data !== null) {
        yearLists.map(b => { // map year to extract data 
            ListMonth.map(c => {//map month to extract data
                let obj_volume = {};
                obj_volume.date = capitalize(c.month) + ", " + b.year;
                get_GraphQL_GrossMargin_Data.map(a => {
                    if (a.values[b.year] !== undefined) {
                        if (a.values[b.year].monthly[c.month] !== undefined) {
                            obj_volume[a.iso] = a.values[b.year].monthly[c.month] // get the value for specific month
                            // below if condition check the iso name and assign iso color and volume
                            if (a.iso === "ERCOT") {
                                obj_volume["ERCOTcolor"] = isoColor(a.iso)
                                obj_volume["ERCOTName"] = a.iso
                            }
                            if (a.iso === "PJM") {
                                obj_volume["PJMcolor"] = isoColor(a.iso)
                                obj_volume["PJMName"] = a.iso
                            }
                            if (a.iso === "ISONE") {
                                obj_volume["ISONEcolor"] = isoColor(a.iso)
                                obj_volume["ISONEName"] = a.iso
                            }
                            if (a.iso === "MISO") {
                                obj_volume["MISOcolor"] = isoColor(a.iso)
                                obj_volume["MISOName"] = a.iso
                            }
                            if (a.iso === "NYISO") {
                                obj_volume["NYISOcolor"] = isoColor(a.iso)
                                obj_volume["NYISOName"] = a.iso
                            }
                        } else {
                            obj_volume[a.iso] = 0
                            if (a.iso === "ERCOT") {
                                obj_volume["ERCOTcolor"] = isoColor(a.iso)
                                obj_volume["ERCOTName"] = a.iso
                            }
                            if (a.iso === "PJM") {
                                obj_volume["PJMcolor"] = isoColor(a.iso)
                                obj_volume["PJMName"] = a.iso
                            }
                            if (a.iso === "ISONE") {
                                obj_volume["ISONEcolor"] = isoColor(a.iso)
                                obj_volume["ISONEName"] = a.iso
                            }
                            if (a.iso === "MISO") {
                                obj_volume["MISOcolor"] = isoColor(a.iso)
                                obj_volume["MISOName"] = a.iso
                            }
                            if (a.iso === "NYISO") {
                                obj_volume["NYISOcolor"] = isoColor(a.iso)
                                obj_volume["NYISOName"] = a.iso
                            }

                        }
                    } else {
                        obj_volume[a.iso] = 0
                        if (a.iso === "ERCOT") {
                            obj_volume["ERCOTcolor"] = isoColor(a.iso)
                            obj_volume["ERCOTName"] = a.iso
                        }
                        if (a.iso === "PJM") {
                            obj_volume["PJMcolor"] = isoColor(a.iso)
                            obj_volume["PJMName"] = a.iso
                        }
                        if (a.iso === "ISONE") {
                            obj_volume["ISONEcolor"] = isoColor(a.iso)
                            obj_volume["ISONEName"] = a.iso
                        }
                        if (a.iso === "MISO") {
                            obj_volume["MISOcolor"] = isoColor(a.iso)
                            obj_volume["MISOName"] = a.iso
                        }
                        if (a.iso === "NYISO") {
                            obj_volume["NYISOcolor"] = isoColor(a.iso)
                            obj_volume["NYISOName"] = a.iso
                        }
                    }
                    return null
                })
                isoTotal_Month_Year_KeyValuePair.push(obj_volume)
                return null
            })
            return null
        })
    }
    return isoTotal_Month_Year_KeyValuePair;
}