import React, { Component } from 'react';
import DashboardKeyValuePairTable from '../../elements/ReactTableReUseAbleComponent/DashboardKeyValuePairTable.js';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
//import TimelineIcon from '@material-ui/icons/Timeline';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { aggregateValue_forGraph } from 'elements/ReactTableReUseAbleComponent/MaterialUITable';
import { IncomeStatementQuery } from "../../graphQL_Query/IncomeStatementQuery"
import { getMonths, isoColor } from '../../utils/helper';
import API, { graphqlOperation } from "@aws-amplify/api";
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';
import { LinearProgress } from '@material-ui/core';
import _ from 'lodash';
const useStyles = makeStyles(styles);

class PortfolioMetricsContainer extends Component {
    constructor() {
        super();
        this.state = {
            isLoaded: false,
            volumeTotals: null,
            graphQLDashboard_Format: null,
            forwardGrossMargins_total: [],
            HedgeMTM_total: [],
            volume_total: [],
            generateYearList: [],
            months: [],
            activeIndex: 0,
        }
    }

    componentDidMount() {
        API.graphql(graphqlOperation(IncomeStatementQuery)).then(({ data: { getIncomeStatement } }) => {
            const months = getMonths();
            //console.log(getIncomeStatement)
            if (getIncomeStatement !== null) {
                this.setState({ getIncomeStatementData: getIncomeStatement, months })
                this.GraphQLReformatData_Yearly_Monthly(getIncomeStatement);
                // this.GraphQLReformatData_UnderContract_Table_Chart()
            }
        });
    }
    GraphQLReformatData_Yearly_Monthly = (getIncomeStatement_Data) => {
        var YearList = [], generateYearList = []
        getIncomeStatement_Data.forEach(row => {
            let newValues = {};
            row.values.forEach(val => newValues[val.year] = val.values.reduce((a, vy) => {
                a.yearly += vy.value;
                YearList.push(val.year)
                a.monthly[vy.month] = vy.value;
                return a;
            }, { yearly: 0, monthly: {} }))
            row.values = newValues;
        });
        //console.log(YearList)
        const uniqueYears = YearList.filter((val, id, array) => array.indexOf(val) === id);
        uniqueYears.sort((a, b) => a - b)
        for (let i = 0; i < uniqueYears.length; i++) {
            generateYearList.push({
                year: uniqueYears[i],
                id: i
            })
        }
       // console.log(uniqueYears)
        //console.log(generateYearList)
        //console.log(this.state.getIncomeStatementData)
        this.setState({
            graphQLDashboard_Format: getIncomeStatement_Data,
            generateYearList: generateYearList
        })
        this.GraphQLReformatData_UnderContract_Table_Chart(getIncomeStatement_Data)
    };

    // this is the main function which return the data for gross margin, volume, and average of it from graphql query data
    GraphQLReformatData_UnderContract_Table_Chart = (graphQLDashboard_Format_Data) => {
        var forwardGrossMargins_total = [],
            HedgeMTM_total = [],
            volume_total = []
        //console.log(this.state.graphQLDashboard_Format)
        //check if the data is not null else do not process
        if (graphQLDashboard_Format_Data !== null) {
            graphQLDashboard_Format_Data.map(a => {
                // gross margin
                if (a.type === "Gross Margin" || a.type === "Contract Margin" ) {
                    ///console.log(a.values)
                    var sum_margin = 0
                    this.state.generateYearList.map(b => {
                        //console.log(a.values['2024'])
                        if (a.values[b.year] !== undefined) {
                            // console.log(b.year)
                            // console.log(a.values[b.year].yearly)
                            sum_margin += a.values[b.year].yearly
                        }
                        return null
                    })
                    // console.log(sum_margin)
                    forwardGrossMargins_total.push({
                        name: a.iso,
                        value: sum_margin,
                        color: isoColor(a.iso),
                        type: 'dollar'
                    })

                }
                //hedge MTM value
                if (a.type === "Hedge MTM") {
                    var sum_hedge = 0
                    this.state.generateYearList.map(b => {
                        if (a.values[b.year] !== undefined) {
                            sum_hedge += a.values[b.year].yearly
                        }
                        return null
                    })
                    HedgeMTM_total.push({
                        name: a.iso,
                        value: sum_hedge,
                        color: isoColor(a.iso),
                        type: 'dollar'
                    })
                }
                //volume
                if (a.type === "Volume") {
                    //console.log(a)
                    var sum_volume = 0
                    this.state.generateYearList.map(b => {
                        if (a.values[b.year] !== undefined) {
                            sum_volume += a.values[b.year].yearly
                        }
                        return null
                    })
                    volume_total.push({
                        name: a.iso,
                        value: sum_volume, // total volume of each iso
                        allValues: a.values, // volume of each iso by year and month
                        color: isoColor(a.iso),
                        type: 'volume'
                    })
                }
                return null
            })
        }
        //console.log(forwardGrossMargins_total)
        //console.log(HedgeMTM_total)
        //console.log(volume_total)
        this.setState({
            forwardGrossMargins_total,
            HedgeMTM_total,
            volume_total,
            isLoaded: true,
        })
        //this.PortfolioMetricsContainerUI()
    };

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    }
    renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle,
            fill, payload, percent } = props;
        //console.log(payload)
        return (
            <g>
                <text x={cx} y={cy} dy={-25} textAnchor="middle" fill={fill} fontSize={18}>{payload.name}</text>
                <text x={cx} y={cy} dy={5} textAnchor="middle" fill={fill} fontSize={18}>
                    {payload.type === "dollar" ? aggregateValue_forGraph(payload.value, true) :
                        payload.type === "volume" ? aggregateValue_forGraph(payload.value, false) :
                            payload.type === null ? payload.value.toFixed(2) + ' $/MWh' : null}</text>
                <text x={cx} y={cy} dy={32} textAnchor="middle" fill={fill} fontSize={18}>
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        );
    };
    // main function to display content for dashboard
    PortfolioMetricsContainerUI = () => {

        const classes = useStyles();

        //variable to make key value pair for data due to the format of recharts graph
        var forwardGrossMargins_KeyValuePair = [],
            volumeTotals_KeyValuePair = [],
            averageUnitMargins_KeyValuePair = [];

        //update variable to make key value pair for data due to the format of recharts graph
        if (this.state.forwardGrossMargins_total[0] !== undefined) {
            for (let i = 0; i < this.state.forwardGrossMargins_total.length; i++) {
                forwardGrossMargins_KeyValuePair.push({
                    name: this.state.forwardGrossMargins_total.map(a => a.name)[i],
                    value: this.state.forwardGrossMargins_total.map(a => a.value)[i] +
                        (this.state.HedgeMTM_total[0] !== undefined ? this.state.HedgeMTM_total.map(a => a.value)[i] : 0),
                    total: _.sum(this.state.forwardGrossMargins_total.map(a => a.value)) + _.sum(this.state.HedgeMTM_total.map(a => a.value)),
                    color: this.state.forwardGrossMargins_total.map(a => a.color)[i],
                    type: 'dollar'
                })
                volumeTotals_KeyValuePair.push({
                    name: this.state.volume_total.map(a => a.name)[i],
                    value: this.state.volume_total.map(a => a.value)[i],
                    total: _.sum(this.state.volume_total.map(a => a.value)),
                    color: this.state.volume_total.map(a => a.color)[i],
                    type: 'volume'
                })
                var value_avg = (this.state.forwardGrossMargins_total.map(a => a.value)[i] +
                    (this.state.HedgeMTM_total[0] !== undefined ? this.state.HedgeMTM_total.map(a => a.value)[i] : 0)) / this.state.volume_total.map(a => a.value)[i]
                var value_avg_total = (_.sum(this.state.forwardGrossMargins_total.map(a => a.value)) + _.sum(this.state.HedgeMTM_total.map(a => a.value))) / _.sum(this.state.volume_total.map(a => a.value))
                averageUnitMargins_KeyValuePair.push({
                    name: this.state.forwardGrossMargins_total.map(a => a.name)[i],
                    value: value_avg * 1000,
                    total: value_avg_total * 1000,
                    color: this.state.forwardGrossMargins_total.map(a => a.color)[i],
                    type: null
                })
            }
        }
        //console.log(forwardGrossMargins_KeyValuePair)
        //console.log(this.state.HedgeMTM_total[0])
        //console.log(this.state.forwardGrossMargins_total)
        // console.log(this.state.volume_total)
        // console.log(this.state.HedgeMTM_total)
        //console.log(averageUnitMargins_KeyValuePair)
        // console.log(volumeTotals_KeyValuePair)
        //console.log(averageUnitMargins_KeyValuePair)
        return (

            <>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card elevation={3}>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <BrokenImageIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>ISO Under Contract</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Card chart className={classes.cardHover}>
                                            <CardBody>
                                                <h4 className={classes.cardTitle}>Total Gross Margin($)</h4>
                                            </CardBody>
                                            <CardHeader>
                                                {this.state.forwardGrossMargins_total[0] !== undefined ?
                                                    <>
                                                        <ResponsiveContainer height={210} width="100%">
                                                            <PieChart>
                                                                <Pie
                                                                    activeIndex={this.state.activeIndex}
                                                                    activeShape={this.renderActiveShape}
                                                                    data={forwardGrossMargins_KeyValuePair}
                                                                    innerRadius={70}
                                                                    dataKey="value"
                                                                    outerRadius={80}
                                                                    paddingAngle={10}
                                                                    onMouseEnter={this.onPieEnter} >
                                                                    {this.state.forwardGrossMargins_total.map((entry) => <Cell fill={entry.color} key={entry.name} />)}
                                                                </Pie>
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                        <DashboardKeyValuePairTable
                                                            table_data={forwardGrossMargins_KeyValuePair}
                                                            condition={true}
                                                            color={this.props.useIsoColor} />
                                                    </>
                                                    : <LinearProgress />}
                                            </CardHeader>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Card chart className={classes.cardHover}>
                                            <CardBody>
                                                <h4 className={classes.cardTitle}>Total Forward Volume(MWh)</h4>
                                            </CardBody>
                                            <CardHeader>
                                                {this.state.volume_total[0] !== undefined ?
                                                    <>
                                                        <ResponsiveContainer height={210} width="100%">
                                                            <PieChart>
                                                                <Pie
                                                                    activeIndex={this.state.activeIndex}
                                                                    activeShape={this.renderActiveShape}
                                                                    data={volumeTotals_KeyValuePair}
                                                                    innerRadius={70}
                                                                    outerRadius={80}
                                                                    dataKey="value"
                                                                    paddingAngle={10}
                                                                    onMouseEnter={this.onPieEnter} >
                                                                    {this.state.volume_total.map((entry) => <Cell fill={entry.color} key={entry.name} />)}

                                                                </Pie>
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                        <DashboardKeyValuePairTable
                                                            table_data={volumeTotals_KeyValuePair}
                                                            condition={false}
                                                            color={this.props.useIsoColor} />
                                                    </>
                                                    : <LinearProgress />}
                                            </CardHeader>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Card chart className={classes.cardHover}>
                                            <CardBody><h4 className={classes.cardTitle}>Unit Margin ($/MWh)</h4></CardBody>
                                            <CardHeader>
                                                {this.state.volume_total[0] !== undefined ?
                                                    <>
                                                        <ResponsiveContainer height={210} width="100%">
                                                            <PieChart>
                                                                <Pie
                                                                    activeIndex={this.state.activeIndex}
                                                                    activeShape={this.renderActiveShape}
                                                                    data={averageUnitMargins_KeyValuePair}
                                                                    innerRadius={70}
                                                                    dataKey="value"
                                                                    outerRadius={80}
                                                                    paddingAngle={10}
                                                                    onMouseEnter={this.onPieEnter} >
                                                                    {averageUnitMargins_KeyValuePair.map((entry) => <Cell fill={entry.color} key={entry.name} />)}
                                                                </Pie>
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                        <DashboardKeyValuePairTable
                                                            table_data_average={averageUnitMargins_KeyValuePair}
                                                            color={this.props.useIsoColor}
                                                        />
                                                    </>
                                                    : <LinearProgress />}
                                            </CardHeader>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
    render() {
        return (
            <>
                <this.PortfolioMetricsContainerUI />
            </>
        )
    }
}
export default PortfolioMetricsContainer;