import React, { Component } from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(styles);
const useStyles = makeStyles(() => ({
    textField: {
        color: 'white',
        // width: "auto",
        textAlign: "right",
        '&:before': {
            borderBottom: "none"
        },
        '&:after': {
            borderBottom: "none"
        },
        icon: {
            fill: 'white',
        },
    },

}));

class SelectedClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promise_clientList: null,
        };
    }

    SelectedClientUI = () => {
        // get the default client from localstorage
        var getDefaultClientName = String(window.localStorage.getItem(`defaultUserName`))
        const [selectedClient, setSelectedClient] = React.useState(getDefaultClientName);
        const classes = useStyles();
        var listClientIDName = []

        var availableAbbreviationList = window.localStorage.getItem(`clientAbbreviationLists`)
        availableAbbreviationList = availableAbbreviationList ? availableAbbreviationList.split(',') : []
        //console.log(availableAbbreviationList)

        var availableDisplayNameList = window.localStorage.getItem(`clientDisplayNameList`)
        availableDisplayNameList = availableDisplayNameList ? availableDisplayNameList.split(',') : []
        // console.log(availableDisplayNameList)

        for (let i = 0; i < availableAbbreviationList.length; i++) {
            listClientIDName.push({
                id: availableAbbreviationList[i],
                name: availableDisplayNameList[i]
            })
        }
        //console.log(listClientIDName)

        // update the client into the local storage on the basis of user selection
        const handleSelectedClient = (event) => {
            const selected = event.target.value;
            //console.log(selected)
            listClientIDName.map(a => {
                if (a.name === selected) {
                    window.localStorage.setItem(`defaultUserName`, a.name)
                    window.localStorage.setItem(`defaultUser`, a.id)
                }
                return null
            })
            setSelectedClient(selected);
            window.location.reload();
        };
        //console.log(listClientIDName[0].id)
        //console.log(selectedClient)
        //var test = ['Demo', 'Pulse', 'Shell']
        return (
            <>
                {selectedClient !== undefined ?
                    <Select
                        inputProps={{
                            name: "selectedClient",
                            id: "simple-select",
                            classes: {
                                icon: classes.textField,
                            },
                        }}
                        className={classes.textField}
                        variant="standard"
                        value={selectedClient}
                        onChange={handleSelectedClient}
                    >
                        {listClientIDName.map(a => (
                            <MenuItem key={a.name} value={a.name} classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                            }}>
                                {a.name}
                            </MenuItem >
                        ))}
                    </Select>
                    : <Select
                        inputProps={{
                            name: "selectedDate",
                            id: "simple-select",
                            classes: {
                                icon: classes.textField,
                            },
                        }}
                        className={classes.textField}
                        variant="standard"
                        value="N/A"
                    >
                        <MenuItem key={null} value={null} selected>
                            N/A
                        </MenuItem >
                    </Select>}
            </>

        )
    }
    render() {
        return (<this.SelectedClientUI props={this.props} />)
    }
}
export default SelectedClient;