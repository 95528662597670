var selectedClient = window.localStorage.getItem(`defaultUser`);
var selectedDate = window.localStorage.getItem(`defaultDate`)

// get available filter for the account level details 
export const getAvailableFiltersAccountLevel = `query getAccountDataFilters {
  getAccountDataFilters(client: "${selectedClient}", id: "${selectedDate}") {
    name
    value {
      ... on filterValueString {
          options
      }
      ... on filterValueDate {
        min
        max
      }
  }
}
}`

export const getISOEDCLevelDetails = `query getAccountData( $sort_by: String, $filters: [filterProperty]) {
  getAccountData(client: "${selectedClient}", id: "${selectedDate}", sort_by: $sort_by, filters: $filters) {
      iso
    value
    count
      edcs {
      edcname
      value
      count
    }
}
}`
export const getISOEDCSelectedAccountLevel = `query getAccountData( $sort_by: String, $filters: [filterProperty]) {
  getAccountData(client: "${selectedClient}", id: "${selectedDate}", sort_by: $sort_by, filters: $filters) {
      iso
    value
    count
      edcs {
      edcname
      value
      count
      accounts {
          account
          grossmargin
          contractvolume
          contracttype
          contractsigneddate
          contractstartdate
          contractenddate
          contractstatus
      }
    }
}
}`

// customer level details on the basis of drop down selection
export const AccountISOEDCListQuery = `query getAccountData {
    getAccountData(client: "${selectedClient}", id: "${selectedDate}") {
        iso
          edcs {
          edcname         
        }
    }
}`


export const getISOEDCVolume = `query getAccountData( $sort_by: String, $filters: [filterProperty]) {
  getAccountData(client: "${selectedClient}", id: "${selectedDate}", sort_by: $sort_by, filters: $filters) {
      iso
    value
    count
      edcs {
      edcname
      value
      count
      accounts {
          account
          grossmargin
          contractvolume
          contracttype
          contractsigneddate
          contractstartdate
          contractenddate
          contractstatus
      }
    }
}
}`

export const CustomerLevelDetailsQuery = `query getAccountData( $sort_by: String, $filters: [filterProperty]) {
  getAccountData(client: "${selectedClient}", id: "${selectedDate}", sort_by: $sort_by, filters: $filters) {
      iso
    value
    count
      edcs {
      edcname
      value
      count
      accounts {
          account
          grossmargin
          contractvolume
          contracttype
          contractsigneddate
          contractstartdate
          contractenddate
      }
    }
}
}`


export const FileDownloadAccountData = `query getFileDownload($filetype: String!, $filters: [filterProperty]) {
  getFileDownload(client:"${selectedClient}", id: "${selectedDate}", filetype: $filetype, filters: $filters)
}
`