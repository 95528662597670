import React, { Component } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { LinearProgress } from '@material-ui/core';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import { CustomizedAxisTickVertical, CustomToolTipDollarMWhAnaDashboard, CustomYAxisMWhDollarAnalysisFiveEDCDash } from '../../utils/rechartHelper'
import StickyHeadTable from '../../elements/ReactTableReUseAbleComponent/ScrollTable'
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);
class UnitMarginAnalysisEDC extends Component {

    UnitMarginAnalysisEDCUI = () => {
        //console.log(this.props.UnitMarginKeyPair)
        const classes = useStyles({
            table: {
                minWidth: 700,
            },
        });
        return (
            <>
                {this.props.UnitMarginKeyPair !== null ?
                    <>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <Card chart className={classes.cardHover}>
                                    <CardHeader>
                                        <h4 className={classes.cardTitle}>Unit Margin EDC Table</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <StickyHeadTable
                                            data={this.props.UnitMarginKeyPair}
                                            measure={"Unit Margin"}
                                            header={"Top performing EDCs"}
                                            currency={3}
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={9}>
                                <Card chart className={classes.cardHover}>
                                    <CardHeader>
                                        <h4 className={classes.cardTitle}>Forward gross margin by EDC</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <ResponsiveContainer width="100%" height="100%" aspect={
                                            this.props.UnitMarginKeyPair.length < 10 ? 2 / 0.5 :
                                                this.props.UnitMarginKeyPair.length <= 10 && this.props.UnitMarginKeyPair.length >= 30 ? 2 / 1 :
                                                    this.props.UnitMarginKeyPair.length > 30 && this.props.UnitMarginKeyPair.length <= 60 ? 2 / 1.5 : 2 / 2}>
                                            <BarChart layout="vertical" height={1000} data={this.props.UnitMarginKeyPair} margin={{ top: 0, right: 5, bottom: 0, left: 40 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis tick={<CustomYAxisMWhDollarAnalysisFiveEDCDash />} type="number" />
                                                <YAxis dataKey="name" tick={<CustomizedAxisTickVertical />} type="category" />
                                                <Tooltip content={<CustomToolTipDollarMWhAnaDashboard />} />
                                                <Bar barSize={20} dataKey='value' >
                                                    {
                                                        this.props.UnitMarginKeyPair.map((entry, index) => {
                                                            return <Cell key={`cell-${index}`} fill={entry.color} /*dataKey={entry.value}*/ />
                                                        })
                                                    }
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </>
                    : <LinearProgress />}
            </>
        )
    }
    render() {
        return (
            <this.UnitMarginAnalysisEDCUI />
        )
    }
}
export default UnitMarginAnalysisEDC;