import React from 'react';
import GrossMarginByEDCLineGraph from '../../elements/GrossMarginVolumeByIsoLineGraph/GrossMarginByEDCLineGraph';
import VolumeByEDCLineGraph from '../../elements/GrossMarginVolumeByIsoLineGraph/VolumeByEDCLineGraph';
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ListMonth, capitalize } from '../../utils/helper';
import { onClick_DownloadIamge_Third, onClick_DownloadIamge_Fourth } from '../../utils/downloadGraphImage'
import { ColorButtonAlignRight } from '../../utils/materialUI_Reuseable_Comp'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
        maxWidth: 180
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));
export default function EDCDataSelector({ GraphQL_GrossMargin_Data_ISO_EDC, GraphQL_Volume_Data_ISO_EDC, yearList }) {
    //console.log(GraphQL_GrossMargin_Data_ISO_EDC)
    var grossMarginLineGraphData_EDC = GraphQL_GrossMargin_Data_ISO_EDC
    var volumeLineGraphData_EDC = GraphQL_Volume_Data_ISO_EDC
    var isLoadingGraph = 0;
    var grossMarginEDCDetails_KeyValuePair = [], volumeEDCDetails_KeyValuePair = [],
        isoList = [], isoSelectedCompanyList = [];
    const classes = useStyles();
    const [selectISOName, setSelectISOName] = React.useState([]); // hooks to handle iso selected state
    const [selectCompanyName, setSelectCompanyName] = React.useState([]); // hooks to handle company selected by iso
    const handleChangeISO = event => {
        setSelectISOName(event.target.value);
    };
    // handle selected company name after selection iso
    const handleChangeCompanyName = event => {
        setSelectCompanyName(event.target.value)
    };
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    //1. create a list of iso to be selected from dropdown option
    //2. create a list of edcname to be selected from the dropdown option
    //3. create a data set for selected iso and edc name from the dropdown option fro Gross Margin
    if (grossMarginLineGraphData_EDC != null) {
        grossMarginLineGraphData_EDC.map(a => { // map the data into iso
            //console.log(a)
            isoList.push(a.iso)
            if (selectISOName === a.iso) {
                isoSelectedCompanyList = a.edcs.map(b => b.name)
                a.edcs.map(b => { // map the data into edc
                    if (selectCompanyName === b.name) {
                        yearList.map(c => { // map year to extract data 
                            ListMonth.map(d => {//map month to extract data
                                let obj_grossMargin_volume = {};
                                obj_grossMargin_volume.date = capitalize(d.month) + ", " + c.year;
                                b.values.map(f => {
                                    if (b.values[c.year] !== undefined) {
                                        if (b.values[c.year].monthly[d.month] !== undefined) {
                                            obj_grossMargin_volume["ISO"] = a.iso
                                            obj_grossMargin_volume["edcName"] = b.name
                                            obj_grossMargin_volume["Type"] = a.type
                                            obj_grossMargin_volume["edcValue"] = b.values[c.year].monthly[d.month]
                                            obj_grossMargin_volume["ISOColor"] = a.color
                                        } else {
                                            obj_grossMargin_volume["ISO"] = a.iso
                                            obj_grossMargin_volume["edcName"] = b.name
                                            obj_grossMargin_volume["Type"] = a.type
                                            obj_grossMargin_volume["edcValue"] = 0
                                            obj_grossMargin_volume["ISOColor"] = a.color
                                        }
                                    } else {
                                        obj_grossMargin_volume["ISO"] = a.iso
                                        obj_grossMargin_volume["edcName"] = b.name
                                        obj_grossMargin_volume["Type"] = a.type
                                        obj_grossMargin_volume["edcValue"] = 0
                                        obj_grossMargin_volume["ISOColor"] = a.color
                                    }
                                    isLoadingGraph = 1
                                    return null
                                })
                                grossMarginEDCDetails_KeyValuePair.push(obj_grossMargin_volume)
                                return null
                            })
                            return null
                        })
                    }
                    return null
                })
            }
            return null
        })
    }
    //extract the data for edc volume upon selection from drop down option 
    if (volumeLineGraphData_EDC != null) {
        volumeLineGraphData_EDC.map(a => { // map the data into iso
            //console.log(a)
            if (selectISOName === a.iso) {
                a.edcs.map(b => { // map the data into edc
                    if (selectCompanyName === b.name) {
                        yearList.map(c => { // map year to extract data 
                            ListMonth.map(d => {//map month to extract data
                                let obj_grossMargin_volume = {};
                                obj_grossMargin_volume.date = capitalize(d.month) + ", " + c.year;
                                b.values.map(f => {
                                    if (b.values[c.year] !== undefined) {
                                        if (b.values[c.year].monthly[d.month] !== undefined) {
                                            obj_grossMargin_volume["ISO"] = a.iso
                                            obj_grossMargin_volume["edcName"] = b.name
                                            obj_grossMargin_volume["Type"] = a.type
                                            obj_grossMargin_volume["edcValue"] = b.values[c.year].monthly[d.month]
                                            obj_grossMargin_volume["ISOColor"] = a.color
                                        } else {
                                            obj_grossMargin_volume["ISO"] = a.iso
                                            obj_grossMargin_volume["edcName"] = b.name
                                            obj_grossMargin_volume["Type"] = a.type
                                            obj_grossMargin_volume["edcValue"] = 0
                                            obj_grossMargin_volume["ISOColor"] = a.color
                                        }
                                    } else {
                                        obj_grossMargin_volume["ISO"] = a.iso
                                        obj_grossMargin_volume["edcName"] = b.name
                                        obj_grossMargin_volume["Type"] = a.type
                                        obj_grossMargin_volume["edcValue"] = 0
                                        obj_grossMargin_volume["ISOColor"] = a.color
                                    }
                                    return null
                                })
                                volumeEDCDetails_KeyValuePair.push(obj_grossMargin_volume)
                                return null
                            })
                            return null
                        })
                    }
                    return null
                })
            }
            return null
        })
    }
    //console.log(grossMarginEDCDetails_KeyValuePair)
    //console.log(volumeEDCDetails_KeyValuePair)
    return (
        <>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">ISO</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectISOName}
                    onChange={handleChangeISO}
                    labelWidth={labelWidth}
                >
                    {isoList[0] !== undefined ? isoList.map(a => (
                        <MenuItem key={a} value={a}>
                            {a}
                        </MenuItem >
                    )) : null}
                </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">EDC</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectCompanyName}
                    onChange={handleChangeCompanyName}
                    labelWidth={labelWidth}
                >
                    {isoSelectedCompanyList[0] !== undefined ? isoSelectedCompanyList.map(a => (
                        <MenuItem key={a} value={a}>
                            {a}
                        </MenuItem>
                    )) : null}
                </Select>
            </FormControl>
            {isLoadingGraph === 1 ?
                <> <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <CardHeader><h4 className={classes.cardIconTitle}>Gross Margin($) of {selectCompanyName != null ? selectCompanyName : "EDC"}
                            &nbsp;<ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                    onClick={onClick_DownloadIamge_Third}>
                                    Download Graph</ColorButtonAlignRight>

                            </h4></CardHeader>
                            <CardBody>
                                <div id="my-node3">
                                    <GrossMarginByEDCLineGraph
                                        grossMarginEDCDetails_KeyValuePair={grossMarginEDCDetails_KeyValuePair}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <CardHeader><h4 className={classes.cardIconTitle}>Volume(MWh) of {selectCompanyName != null ? selectCompanyName : "EDC"}
                            &nbsp;<ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                    onClick={onClick_DownloadIamge_Fourth}>
                                    Download Graph</ColorButtonAlignRight>
                            </h4></CardHeader>
                            <CardBody>
                                <div id="my-node4">
                                    <VolumeByEDCLineGraph
                                        volumeEDCDetails_KeyValuePair={volumeEDCDetails_KeyValuePair}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                </> : <><p>Please Select the Options to See the Graph</p></>
            }
        </>
    )
}