import React, { Component } from 'react';
import IncomeStatement from '../../elements/IncomeStatement/IncomeStatement';
class IncomeStatementView extends Component {
    render() {
        return (
            <>
                <IncomeStatement />
            </>
        )
    }
}
export default IncomeStatementView;