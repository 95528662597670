import React, { Component } from 'react';
import API, { graphqlOperation } from "@aws-amplify/api";
import { getISOEDCSelectedAccountLevel } from "../../graphQL_Query/AccountDataQuery"
import SearchDataInTable, {
    DefaultColumnFilter, SelectColumnFilter,//SingleDateColumnFilter, //RangeDateColumnFilter
} from '../../elements/ReactTableReUseAbleComponent/SearchDataInTable'
import { isoColor } from '../../utils/helper'
import { LinearProgress } from '@material-ui/core';

class CustomerDetailsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // handle the view change and save the selected ISO and EDC Name
            accountLevelFilters: [],
            // filter the data on the basis of selected ISO and EDC and add filters
            promise_graphlqlAccountFilterData: null,
        };
    }
    static getDerivedStateFromProps(props) {
        var response_AccountFilterData
        var accountLevelFilters = []
        // console.log(props.filtersGraphQLAccountData)
        // console.log(props.selectedISOFilter)
        //  console.log(props.selectedEDCFilter)
        //create filter list on the basis of props received
        accountLevelFilters.push({
            filters: [
                {
                    name: `${'iso'}`,
                    value: props.selectedISOFilter,
                },
                {
                    name: `${'edcname'}`,
                    value: props.selectedEDCFilter,
                },
                { // contract type
                    name: props.filtersGraphQLAccountData.filters[2].name,
                    value: props.filtersGraphQLAccountData.filters[2].value,
                },
                { // contract status
                    name: props.filtersGraphQLAccountData.filters[3].name,
                    value: props.filtersGraphQLAccountData.filters[3].value,

                },
                { // brand name
                    name: props.filtersGraphQLAccountData.filters[4].name,
                    value: props.filtersGraphQLAccountData.filters[4].value,
                },
                { // broker type
                    name: props.filtersGraphQLAccountData.filters[5].name,
                    value: props.filtersGraphQLAccountData.filters[5].value,
                },
                {// contract signdate - start
                    name: props.filtersGraphQLAccountData.filters[6].name,
                    value: props.filtersGraphQLAccountData.filters[6].value,
                    comparator: props.filtersGraphQLAccountData.filters[6].comparator
                },
                { //contract startdate - start
                    name: props.filtersGraphQLAccountData.filters[7].name,
                    value: props.filtersGraphQLAccountData.filters[7].value,
                    comparator: props.filtersGraphQLAccountData.filters[7].comparator
                },
                {//contract startdate - end
                    name: props.filtersGraphQLAccountData.filters[8].name,
                    value: props.filtersGraphQLAccountData.filters[8].value,
                    comparator: props.filtersGraphQLAccountData.filters[8].comparator
                },
                {//contract endate - start
                    name: props.filtersGraphQLAccountData.filters[9].name,
                    value: props.filtersGraphQLAccountData.filters[9].value,
                    comparator: props.filtersGraphQLAccountData.filters[9].comparator
                },
                {//contract endate - end
                    name: props.filtersGraphQLAccountData.filters[10].name,
                    value: props.filtersGraphQLAccountData.filters[10].value,
                    comparator: props.filtersGraphQLAccountData.filters[10].comparator
                },
                //contract endate - end
                props.filtersGraphQLAccountData.filters[11].value !== undefined ? {
                    name: props.filtersGraphQLAccountData.filters[11].name,
                    value: props.filtersGraphQLAccountData.filters[11].value,
                    comparator: props.filtersGraphQLAccountData.filters[11].comparator
                } : null,
            ]
        })
        //console.log(accountLevelFilters)
        // fetch the data on the basis of filters
        if (accountLevelFilters[0].filters[11].value !== undefined) {
            response_AccountFilterData = API.graphql(graphqlOperation(getISOEDCSelectedAccountLevel,
                {
                    filters: accountLevelFilters[0].filters,
                    sort_by: `${props.marginType}`
                }))
                .then(({ data: getAccountDataQuery }) => {
                    //console.log(getAccountDataQuery.getAccountData)
                    return getAccountDataQuery.getAccountData
                }).catch(a => {
                    console.log(a.errors[0].message)
                });;
            return {
                promise_graphlqlAccountFilterData: response_AccountFilterData
            };
        }
    }

    CustomerTableDetailsUI = () => {
        var AccountDataChart_KeyValuePair_AccountData_Gross = [], AccountDataChart_KeyValuePair_AccountData_Unit = []
        const [promiseAccountData, setPromiseAccountData] = React.useState()
        const [data, setData] = React.useState()
        const skipResetRef = React.useRef(false)
        var SearchDataTable = new SearchDataInTable();
        // unpromis fetch data of graphql and save it in local state
        if (this.state.promise_graphlqlAccountFilterData !== null) {
            if (this.state.promise_graphlqlAccountFilterData !== undefined) {
                this.state.promise_graphlqlAccountFilterData.then(a => {
                    if (a !== undefined) {
                        //console.log(a)
                        setPromiseAccountData(a)
                    }
                    AccountDataChart_KeyValuePair_AccountData_Gross = []
                    AccountDataChart_KeyValuePair_AccountData_Unit = []
                })
            }
        }
        // console.log(promiseAccountData)
        // reorganzie data on the basis of visualization graph requirement and then sort it
        if (this.state.promise_graphlqlAccountFilterData !== null) {
            if (this.state.promise_graphlqlAccountFilterData !== undefined) {
                if (promiseAccountData !== undefined) {
                    promiseAccountData.map(a => {
                        a.edcs.map(b => {
                            b.accounts.map(c => {
                                AccountDataChart_KeyValuePair_AccountData_Gross.push({
                                    ISO: a.iso,
                                    EDCName: b.edcname,
                                    color: isoColor(a.iso),
                                    AccountNo: c.account,
                                    GrossMargin: c.grossmargin,
                                    UnitMargin: (c.grossmargin / c.contractvolume) * 1000,
                                    Volume: c.contractvolume,
                                    ContractType: c.contracttype,
                                    Brand: c.brand,
                                    Broker: c.broker,
                                    ContractStatus: c.contractstatus,
                                    ContractSignDate: c.contractsigneddate,
                                    ContractStartDate: c.contractstartdate,
                                    ContractEndDate: c.contractenddate,
                                })
                                AccountDataChart_KeyValuePair_AccountData_Unit.push({
                                    ISO: a.iso,
                                    EDCName: b.edcname,
                                    color: isoColor(a.iso),
                                    AccountNo: c.account,
                                    GrossMargin: c.grossmargin,
                                    UnitMargin: (c.grossmargin / c.contractvolume) * 1000,
                                    Volume: c.contractvolume,
                                    ContractType: c.contracttype,
                                    Brand: c.brand,
                                    Broker: c.broker,
                                    ContractStatus: c.contractstatus,
                                    /*   ...(c.brand ? { Brand: c.brand } : null),
                                       ...(c.broker ? { Broker: c.broker } : null),
                                       ...(c.contractstatus ? { ContractStatus: c.contractstatus } : null),*/
                                    ContractSignDate: c.contractsigneddate,
                                    ContractStartDate: c.contractstartdate,
                                    ContractEndDate: c.contractenddate,
                                })
                                return null
                            })
                            return null
                        })
                        return null
                    })
                    AccountDataChart_KeyValuePair_AccountData_Gross.sort((a, b) => b.GrossMargin - a.GrossMargin)
                    AccountDataChart_KeyValuePair_AccountData_Unit.sort((a, b) => b.UnitMargin - a.UnitMargin)
                }
            }
        }
        const columns = [
            {
                Header: ' ',
                columns: [
                    { // get the account number of customers
                        Header: 'Account No',
                        accessor: 'AccountNo',
                        Filter: DefaultColumnFilter
                    },
                    {
                        Header: 'ISO',
                        accessor: 'ISO',
                        Filter: SelectColumnFilter,
                        filter: 'includes'

                    },
                    {
                        Header: 'EDC',
                        accessor: 'EDCName',
                        Filter: SelectColumnFilter,
                        filter: 'includes'

                    },
                    {
                        Header: 'Gross Margin',
                        accessor: row => {
                            return row.GrossMargin.toFixed(2)
                        },
                    },
                    {
                        Header: 'Volume (MWh)',
                        accessor: row => {
                            return row.Volume

                        },
                    },
                    {
                        Header: 'Unit Margin ($/MWh)',
                        accessor: row => {
                            return row.UnitMargin.toFixed(2)
                        },
                    },
                    { // display all contract type and filter the data on the basis of selection
                        Header: 'Contract Type',
                        accessor: 'ContractType',
                        Filter: SelectColumnFilter,
                        filter: 'includes'

                    },
                    {// display all sign date and filter the data on the basis of selection
                        Header: 'Sign Date',
                        accessor: 'ContractSignDate',
                    },
                    {// display all start date and filter the data on the basis of selection
                        Header: 'Start Date',
                        accessor: 'ContractStartDate',
                    },
                    {// display all end date and filter the data on the basis of selection
                        Header: 'End Date',
                        accessor: 'ContractEndDate',
                    },
                ],
            },
        ]
        const updateMyData = (rowIndex, columnId, value) => {
            // We also turn on the flag to not reset the page
            skipResetRef.current = true
            setData(old =>
                old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...row,
                            [columnId]: value,
                        }
                    }
                    return row
                })
            )
        }
        React.useEffect(() => {
            skipResetRef.current = false
        }, [data])
        return (
            <>
                {AccountDataChart_KeyValuePair_AccountData_Gross[0] !== undefined ?
                    <>{this.props.marginType !== "unit" ?
                        <SearchDataTable.SearchDataInTableUI
                            columns={columns}
                            data={AccountDataChart_KeyValuePair_AccountData_Gross}
                            updateMyData={updateMyData}
                            skipReset={skipResetRef.current}
                        />
                        :
                        <SearchDataTable.SearchDataInTableUI
                            columns={columns}
                            data={AccountDataChart_KeyValuePair_AccountData_Unit}
                            updateMyData={updateMyData}
                            skipReset={skipResetRef.current}
                        />
                    }
                    </>
                    :
                    <>  <LinearProgress /></>}
            </>
        )
    }
    render() {
        return (<this.CustomerTableDetailsUI />)
    }
}
export default CustomerDetailsTable;