
// query to get list available client or company list
export const getListOfAvailableClient = ` query getAvailableClients {
  getAvailableClients {
      client
      displayName
}
}`

export const getListOfAvailableClient1 = `query MyQuery {
    getAvailableClients
  }`
export const defaultSelectedClient = window.localStorage.getItem(`defaultUser`)
//window.localStorage.setItem(`test1`, defaultSelectedClient)
//console.log(defaultSelectedClient)
// query to get list available client or company report date
export const getListOfDates = `query getAvailableReports {
	getAvailableReports(client:"${defaultSelectedClient}")
}`



