import React, { Component } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { AccountISOEDCListQuery } from "../../graphQL_Query/AccountDataQuery"
import AccountCustomerDetailsGraphTable from './AccountCustomerDetailsGraphTable'
import API, { graphqlOperation } from "@aws-amplify/api";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));

class AccountCustomerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            graphQLCustomerLevelDetails: null,
            graphqlISOEDCList: null,
            selectISOName: null,
            selectEDCName: null,
            margin_type: 'volume',
        }
    }
    componentDidMount() {
        // creat the list iso and edc name list to select it from drop down list
        API.graphql(graphqlOperation(AccountISOEDCListQuery))
            .then(({ data: getAccountDataQuery }) => {
                var graphqlISOEDCList = getAccountDataQuery.getAccountData
                //console.log(graphqlISOEDCList)
                // console.log(this.state.isoName)
                this.setState({ graphqlISOEDCList })
            }).catch(a => {
                console.log(a.errors[0].message)
            });;
    }

    AccountCustomerDetailsUI = () => {
        const classes = useStyles();
        var isoList = [], edcList = [];
        var filtersGraphQLData = {
            filters: [
                {
                    name: `${'iso'}`,
                    value: `${this.state.selectISOName}`
                },
                {
                    name: `${'edcname'}`,
                    value: `${this.state.selectEDCName}`
                }
            ]
        }
        // handle selected iso name
        const handleChangeISO = event => {
            this.setState({
                selectISOName: event.target.value,
                selectEDCName: null
            })
        };
        // handle selected company name after selection iso
        const handleChangeCompanyName = event => {
            this.setState({
                selectEDCName: event.target.value
            })
        };

        const inputLabel = React.useRef(null);
        const [labelWidth, setLabelWidth] = React.useState(0);
        React.useEffect(() => {
            setLabelWidth(inputLabel.current.offsetWidth);
        }, []);

        //create a list of iso and edc to select from dropdown list 
        if (this.state.graphqlISOEDCList !== null) {
            isoList = this.state.graphqlISOEDCList.map(a => a.iso)
            // console.log(isoList)
            this.state.graphqlISOEDCList.map(a => {
                if (this.state.selectISOName === a.iso) {
                    edcList = a.edcs.map(b => b.edcname)
                }
                return null
            })
        }
        // console.log(isoList)
        //  console.log(this.state.selectISOName)
        //  console.log(edcList)
        //  console.log(this.state.selectEDCName)
        return (
            <>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">ISO</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.selectISOName === null ? '': this.state.selectISOName}
                        onChange={handleChangeISO}
                        labelWidth={labelWidth}
                    >
                        {isoList.map(a => (
                            <MenuItem key={a} value={a}>
                                {a}
                            </MenuItem >
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">EDC</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.selectEDCName === null ? '':this.state.selectEDCName}
                        onChange={handleChangeCompanyName}
                        labelWidth={labelWidth}
                    >
                        {edcList.map(a => (
                            <MenuItem key={a} value={a}>
                                {a}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {this.state.selectISOName !== null && this.state.selectEDCName !== null ?
                    <>
                        {/*this will draw graph of customer details on the basis of selection of iso and edc from the dropdown list*/}
                        <AccountCustomerDetailsGraphTable
                            selectISONameGraphTable={this.state.selectISOName}
                            selectEDCNameGraphTable={this.state.selectEDCName}
                            filtersGraphQLData={filtersGraphQLData}
                            marginType={this.state.margin_type}
                        />
                    </>
                    : <><p>Please Select the Options to See the Graph</p></>
                }
            </>
        )
    }
    render() {
        return (<this.AccountCustomerDetailsUI />)
    }
}
export default AccountCustomerDetails;