import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import RearrangeDataGrossMarginVolume from './RearrangeDataGrossMarginVolume'
import ReusableGraphGrossMarginVolume from './ReusableGraphGrossMarginVolume'
import RearrangeGraphISOTotal from './RearrangeGraphISOTotal'
import ReusableGraphISOTotal from './ReusableGraphISOTotal'
import { LinearProgress } from '@material-ui/core';
import { onClick_DownloadIamge, onClick_DownloadIamge_Second } from '../../utils/downloadGraphImage'
import { ColorButtonAlignRight } from '../../utils/materialUI_Reuseable_Comp'
import DashboardKeyValuePairTable from '../../elements/ReactTableReUseAbleComponent/DashboardKeyValuePairTable.js';

const useStyles = makeStyles(styles);
class VolumeByIsoLineGraph extends Component {
    VolumeByIsoLineGraphUI = () => {
        var get_GraphQL_Volume_Data = this.props.GraphQL_Volume_Data_ISO
        var yearLists = this.props.yearList
        const [graphViewState, setGraphViewState] = React.useState('0');
        const classes = useStyles();
        //variable to make key value pair for data due to the format of recharts graph
        var volumeLineGraphData_Series_KeyValuePair = [], volume_yearlytotal
        // reorganize the data in the formart graph can be drawn
        volumeLineGraphData_Series_KeyValuePair = RearrangeDataGrossMarginVolume(get_GraphQL_Volume_Data, yearLists)
        volume_yearlytotal = RearrangeGraphISOTotal(get_GraphQL_Volume_Data, yearLists)
        //console.log("volume")
        //console.log(volume_yearlytotal)
        const handleViewGraphChnage = event => {
            setGraphViewState(event.target.value);
        };
        return (
            <>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={8}>
                        <Card elevation={3}>
                            <CardHeader color="success" icon>
                                <h4 className={classes.cardIconTitle}>Volume of ISO's&nbsp;&nbsp;
                                <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                        onClick={onClick_DownloadIamge}>
                                        Download Graph</ColorButtonAlignRight></h4>
                            </CardHeader>
                            <CardBody>
                                <div id="my-node">
                                    <ReusableGraphISOTotal
                                        data_to_drawGraph_total={volume_yearlytotal}
                                        data_to_drawGraph_Monthly={get_GraphQL_Volume_Data}
                                        View_Dollar_Graph={false}
                                        yearList={this.props.yearList}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Card chart className={classes.cardHover}>
                            <CardBody>
                                <h4 className={classes.cardTitle}>Total Volume Table</h4>
                            </CardBody>
                            <CardHeader>
                                {volume_yearlytotal[0] !== undefined ?
                                    <DashboardKeyValuePairTable
                                        table_data={volume_yearlytotal}
                                        condition={false}
                                    /> : <LinearProgress />}
                            </CardHeader>
                        </Card>
                    </GridItem>
                    <GridItem xs={12}>
                        <Card elevation={3}>
                            <CardHeader color="success" icon>
                                <h4 className={classes.cardIconTitle}>View Data by Monthly of Volume of Each ISO</h4>
                            </CardHeader>
                            <CardBody>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Please Select the View</FormLabel>
                                    <RadioGroup aria-label="gender"
                                        name="gender1"
                                        value={graphViewState}
                                        onChange={handleViewGraphChnage}
                                        style={{ display: 'inline', flexDirection: 'row' }}>
                                        <FormControlLabel value="0" control={<Radio color="primary" />} label="Line Graph" />
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Transparent Area Fill View" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="Stacked Bar Graph View" />
                                    </RadioGroup>
                                </FormControl>
                                <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                    onClick={onClick_DownloadIamge_Second}>
                                    Download Graph</ColorButtonAlignRight>&nbsp;&nbsp;
                {volumeLineGraphData_Series_KeyValuePair[0] !== null ?
                                    <>
                                        <div id="my-node2">
                                            <ReusableGraphGrossMarginVolume
                                                data_to_drawGraph={volumeLineGraphData_Series_KeyValuePair}
                                                graphViewSelection={graphViewState}
                                                View_Dollar_Graph={false}
                                            />
                                        </div>
                                    </> : <LinearProgress />}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
    render() {
        return (
            <this.VolumeByIsoLineGraphUI />
        )
    }
}
export default VolumeByIsoLineGraph;