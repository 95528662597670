import React, { Component, useState } from 'react';
//import ISOEDCLevelDetails from './ISOEDCLevelDetails'
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { DateRangePicker } from 'react-date-range';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridContainer from "components/Grid/GridContainer.js";
import ListItemText from '@material-ui/core/ListItemText';
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Bar, BarChart, Cell
} from 'recharts';
import { CustomizedAxisTickVertical, CustomXAxisDollarVertical, CustomXAxisDollarMWhVertical } from '../../utils/rechartHelper'
import { onClick_DownloadIamge } from '../../utils/downloadGraphImage'
import CustomerAccountLevelDetails from './CustomerAccountLevelDetails'
import { isoColor, formatter } from '../../utils/helper'
import { aggregateValue } from 'elements/ReactTableReUseAbleComponent/MaterialUITable';
import { getISOEDCLevelDetails } from "../../graphQL_Query/AccountDataQuery"
import { getAvailableFiltersAccountLevel, FileDownloadAccountData } from "../../graphQL_Query/AccountDataQuery"
import { ColorRadioButton, ColorFormlabel, ColorButtonAlignRight, ColorButton, ColorCheckBox } from '../../utils/materialUI_Reuseable_Comp'
import API, { graphqlOperation } from "@aws-amplify/api";
import { monthNumber_to_Name } from '../../utils/helper'
import CustomerDetailsTable from './CustomerDetailsTable'

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        //  minWidth: 120,
        //  maxWidth: 300,
        width: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AccountDataContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRanges: new Map(),
            checkedItems: new Map(),
            graphqlAvailableFiltersAccountLevel: null,
            graphqlISOList: {},
            graphqlEDCName: {},
            graphqlContractStatus: {},
            graphqlOptionList: {},
            graphqlBrandName: {},
            graphqlBrokerType: {},
            graphqlSignDateRange: {},
            graphqlStartDateRange: {},
            graphqlEndDateRange: {},

            isShowCalendar_signDate: false,
            isShowCalendar_startDate: false,
            isShowCalendar_endDate: false,

            margin_type: 'gross',
            promise_getAccountDataLinkExcel: null,

            checkedBoxContractType: [],
            checkedBoxContractStatus: [],
            checkedBoxBrandName: [],
            checkedBoxBroker: [],

            updatedFilterToGetData: null,

            promise_graphqlISOEDCFilterAccountLevel: null,
            totalISO_Hide: true,
            selectedISO: null,
            totalEDC_Hide: true,
            selectedEDC: null,

        }
    }
    componentDidMount() {
        // creat the list iso and edc name list to select it from drop down list
        API.graphql(graphqlOperation(getAvailableFiltersAccountLevel))
            .then(({ data: getAccountDataQuery }) => {
                var graphqlAvailableFiltersAccountLevel = getAccountDataQuery.getAccountDataFilters
                //console.log(graphqlAvailableFiltersAccountLevel)
                graphqlAvailableFiltersAccountLevel.map(a => {
                    if (a.name === "contracttype") {
                        this.setState({ graphqlOptionList: a.value['options'] })
                    } else if (a.name === "contractsigneddate") {
                        this.setState({ graphqlSignDateRange: a.value })
                    } else if (a.name === "contractstartdate") {
                        this.setState({ graphqlStartDateRange: a.value })
                    } else if (a.name === "contractenddate") {
                        this.setState({ graphqlEndDateRange: a.value })
                    } else if (a.name === "contractstatus") {
                        this.setState({ graphqlContractStatus: a.value['options'] })
                    } else if (a.name === "iso") {
                        this.setState({ graphqlISOList: a.value['options'] })
                    } else if (a.name === "edcname") {
                        this.setState({ graphqlEDCName: a.value['options'] })
                    } else if (a.name === "brand") {
                        this.setState({ graphqlBrandName: a.value['options'] })
                    } else if (a.name === "broker") {
                        this.setState({ graphqlBrokerType: a.value['options'] })
                    } else {
                        this.setState({ graphqlAvailableFiltersAccountLevel })
                    }
                    return null
                })
                console.log(graphqlAvailableFiltersAccountLevel)
            }).catch(a => {
                console.log(a.errors[0].message)
            });
    }
    static getDerivedStateFromProps(props, state) {
        // console.log(state.updatedFilterToGetData)
        //console.log(state)
        var response_ISOEDC
        // fetch the data on the basis of state change and render the UI component
        if (state.updatedFilterToGetData !== null) {
            //console.log('after change in state')
            // filter after user selection and has 
            var filtersGraphQLAccountData_Updated = {
                filters: [
                    {
                        name: `${'iso'}`,
                        value: state.selectedISO !== null ? state.selectedISO : state.graphqlISOList,
                    },
                    {
                        name: `${'edcname'}`,
                        value: state.selectedEDC !== null ? state.selectedEDC : state.graphqlEDCName,
                    },
                    {
                        name: `${'contracttype'}`,
                        value: state.checkedBoxContractType[0] !== undefined ? state.checkedBoxContractType : state.graphqlOptionList,
                    },
                    state.graphqlContractStatus[0] !== undefined ?
                        {
                            name: `${'contractstatus'}`,
                            value: state.checkedBoxContractStatus[0] !== undefined ? state.checkedBoxContractStatus : state.graphqlContractStatus,
                        } : null,
                    state.graphqlBrandName[0] !== undefined ?
                        {
                            name: `${'brand'}`,
                            value: state.checkedBoxBrandName[0] !== undefined ? state.checkedBoxBrandName : state.graphqlBrandName,
                        } : null,
                    state.graphqlBrokerType[0] !== undefined ?
                        {
                            name: `${'broker'}`,
                            value: state.checkedBoxBroker[0] !== undefined ? state.checkedBoxBroker : state.graphqlBrokerType,
                        } : null,
                    {
                        name: `${'contractsigneddate'}`,
                        value: `${state.updatedFilterToGetData.filters[6].value}`,
                        comparator: `${state.updatedFilterToGetData.filters[6].comparator}`
                    },
                    {
                        name: `${'contractsigneddate'}`,
                        value: `${state.updatedFilterToGetData.filters[7].value}`,
                        comparator: `${state.updatedFilterToGetData.filters[7].comparator}`
                    },
                    {
                        name: `${'contractstartdate'}`,
                        value: `${state.updatedFilterToGetData.filters[8].value}`,
                        comparator: `${state.updatedFilterToGetData.filters[8].comparator}`
                    },
                    {
                        name: `${'contractstartdate'}`,
                        value: `${state.updatedFilterToGetData.filters[9].value}`,
                        comparator: `${state.updatedFilterToGetData.filters[9].comparator}`
                    },
                    {
                        name: `${'contractenddate'}`,
                        value: `${state.updatedFilterToGetData.filters[10].value}`,
                        comparator: `${state.updatedFilterToGetData.filters[10].comparator}`
                    },
                    {
                        name: `${'contractenddate'}`,
                        value: `${state.updatedFilterToGetData.filters[11].value}`,
                        comparator: `${state.updatedFilterToGetData.filters[11].comparator}`
                    }
                ]
            }
            //console.log(state.updatedFilterToGetData)
            //console.log(filtersGraphQLAccountData_Updated)
            // console.log(filtersGraphQLAccountData_Updated.filters[9])
            //  console.log(state.updatedFilterToGetData.filters[9].value)
            if (filtersGraphQLAccountData_Updated.filters[9].value !== undefined) {
                response_ISOEDC = API.graphql(graphqlOperation(getISOEDCLevelDetails,
                    {
                        filters: filtersGraphQLAccountData_Updated.filters,
                        sort_by: `${state.margin_type}`
                    }))
                    .then(({ data: getAccountDataQuery }) => {
                        // console.log(getAccountDataQuery.getAccountData[0])
                        return getAccountDataQuery.getAccountData
                    }).catch(a => {
                        console.log(a.errors[0].message)
                    });
                return {
                    promise_graphqlISOEDCFilterAccountLevel: response_ISOEDC
                };
            }
        } else {
            //console.log('initial render')
            // filter for initial rendering 
            var filtersGraphQLAccountDataDefault = {
                filters: [
                    {
                        name: `${'iso'}`,
                        value: state.graphqlISOList,
                    },
                    {
                        name: `${'edcname'}`,
                        value: state.graphqlEDCName,
                    },
                    {
                        name: `${'contracttype'}`,
                        value: state.graphqlOptionList,
                    },
                    state.graphqlContractStatus[0] !== undefined ?
                        {
                            name: `${'contractstatus'}`,
                            value: state.graphqlContractStatus,
                        } : null,
                    state.graphqlBrandName[0] !== undefined ?
                        {
                            name: `${'brand'}`,
                            value: state.graphqlBrandName,
                        } : null,
                    state.graphqlBrokerType[0] !== undefined ?
                        {
                            name: `${'broker'}`,
                            value: state.graphqlBrokerType,
                        } : null,
                    {
                        name: `${'contractsigneddate'}`,
                        value: `${state.graphqlSignDateRange.max}`,
                        comparator: `${"lte"}`
                    },
                    {
                        name: `${'contractsigneddate'}`,
                        value: `${state.graphqlSignDateRange.min}`,
                        comparator: `${"gte"}`
                    },
                    {
                        name: `${'contractstartdate'}`,
                        value: `${state.graphqlStartDateRange.max}`,
                        comparator: `${"lte"}`
                    },
                    {
                        name: `${'contractstartdate'}`,
                        value: `${state.graphqlStartDateRange.min}`,
                        comparator: `${"gte"}`
                    },
                    {
                        name: `${'contractenddate'}`,
                        value: `${state.graphqlEndDateRange.max}`,
                        comparator: `${"lte"}`
                    },
                    {
                        name: `${'contractenddate'}`,
                        value: `${state.graphqlEndDateRange.min}`,
                        comparator: `${"gte"}`
                    }
                ]
            }
            console.log(filtersGraphQLAccountDataDefault)
            //console.log(state.graphqlEndDateRange.min)
            if (state.graphqlEndDateRange.min !== undefined) {
                response_ISOEDC = API.graphql(graphqlOperation(getISOEDCLevelDetails,
                    {
                        filters: filtersGraphQLAccountDataDefault.filters,
                        sort_by: `${state.margin_type}`
                    }))
                    .then(({ data: getAccountDataQuery }) => {
                        console.log(getAccountDataQuery.getAccountData[0])
                        return getAccountDataQuery.getAccountData
                    }).catch(a => {
                        console.log(a.errors[0].message)
                    });
                return {
                    promise_graphqlISOEDCFilterAccountLevel: response_ISOEDC
                };
            }
        }
    }

    AccountDataContainerUI = () => {
        //console.log(this.state.graphqlOptionList)
        //  console.log(this.state.graphqlSignDateRange)
        //  console.log(this.state.graphqlStartDateRange)
        //   console.log(this.state.graphqlEndDateRange)
        const classes = useStyles();
        const { isShowCalendar_signDate, isShowCalendar_startDate, isShowCalendar_endDate } = this.state;
        const [anchorEl_startDate, setAnchorEl_startDate] = React.useState();
        const [anchorEl_signDate, setAnchorEl_signDate] = React.useState();
        const [anchorEl_endDate, setAnchorEl_endDate] = React.useState();
        // save the key pair value for iso and edc
        var AccountDataChart_KeyValuePair_ISO = [], AccountDataChart_KeyValuePair_EDC = []
        const [selected_ISO_Name, setSelected_ISO_Name] = React.useState(null); // set selected iso name
        const [promiseDataAccountData, setPromiseDataAccountData] = React.useState() // unpromise the data and save into state to visualize into graph
        const [promiseDataCSVLink, setPromiseDataCSVLink] = React.useState() // unpromise the csv link and save into state to download the data
        const [open, setOpen] = React.useState(false); // set the state for dialog open
        const [CSVLinkNotAvailable, setCSVLinkNotAvailable] = React.useState(false)
        //console.log(checkedBoxContractType[0])
        const [selectedDate, setSelectedDate] = useState({
            contractSignDate: {
                startDate: null,//new Date(),
                endDate: null,//addDays(new Date(), 7),
                color: "#156099",
                key: 'contractSignDate'
            },
            contractStartDate: {
                startDate: null,//new Date(),
                endDate: null,//addDays(new Date(), 7),
                color: "#156099",
                key: 'contractStartDate'
            },
            contractEndDate: {
                startDate: null,//new Date(),
                endDate: null,//addDays(new Date(), 7),
                color: "#156099",
                key: 'contractEndDate',
            },
        });
        // console.log(selectedDate)

        // this filter will fetch the data into ISOEDCLevelDetails files on the basis of user selection 
        // it will filter contract type and dates 
        // for comparator with default dates we have to use opposite comparator like lte => gte and gte => lte
        var filtersGraphQLAccountData = {
            filters: [
                {
                    name: `${'iso'}`,
                    value: this.state.selectedISO !== null ? this.state.selectedISO : this.state.graphqlISOList,
                },
                {
                    name: `${'edcname'}`,
                    value: this.state.selectedEDC !== null ? this.state.selectedEDC : this.state.graphqlEDCName,
                },
                {
                    name: `${'contracttype'}`,
                    value: this.state.checkedBoxContractType[0] !== undefined ? this.state.checkedBoxContractType : this.state.graphqlOptionList,
                },
                this.state.graphqlContractStatus[0] !== undefined ?
                    {
                        name: `${'contractstatus'}`,
                        value: this.state.checkedBoxContractStatus[0] !== undefined ? this.state.checkedBoxContractStatus : this.state.graphqlContractStatus,
                    } :
                    {
                        name: `${'contracttype'}`,
                        value: this.state.checkedBoxContractType[0] !== undefined ? this.state.checkedBoxContractType : this.state.graphqlOptionList,
                    },
                this.state.graphqlBrandName[0] !== undefined ?
                    {
                        name: `${'brand'}`,
                        value: this.state.checkedBoxBrandName[0] !== undefined ? this.state.checkedBoxBrandName : this.state.graphqlBrandName,
                    } :
                    {
                        name: `${'contracttype'}`,
                        value: this.state.checkedBoxContractType[0] !== undefined ? this.state.checkedBoxContractType : this.state.graphqlOptionList,
                    },
                this.state.graphqlBrokerType[0] !== undefined ?
                    {
                        name: `${'broker'}`,
                        value: this.state.checkedBoxBroker[0] !== undefined ? this.state.checkedBoxBroker : this.state.graphqlBrokerType,
                    } :
                    {
                        name: `${'contracttype'}`,
                        value: this.state.checkedBoxContractType[0] !== undefined ? this.state.checkedBoxContractType : this.state.graphqlOptionList,
                    },
                {
                    name: `${'contractsigneddate'}`,
                    value: `${selectedDate.contractSignDate.startDate !== null ?
                        selectedDate.contractSignDate.startDate.getFullYear() + '-' + (selectedDate.contractSignDate.startDate.getMonth() + 1) + '-' + selectedDate.contractSignDate.startDate.getDate()
                        : this.state.graphqlSignDateRange.max}`,
                    comparator: selectedDate.contractSignDate.startDate === null ? `${"lte"}` : `${"gte"}`
                },
                {
                    name: `${'contractsigneddate'}`,
                    value: `${selectedDate.contractSignDate.endDate !== null ?
                        selectedDate.contractSignDate.endDate.getFullYear() + '-' + (selectedDate.contractSignDate.endDate.getMonth() + 1) + '-' + selectedDate.contractSignDate.endDate.getDate()
                        : this.state.graphqlSignDateRange.min}`,
                    comparator: selectedDate.contractSignDate.endDate === null ? `${"gte"}` : `${"lte"}`
                },
                {
                    name: `${'contractstartdate'}`,
                    value: `${selectedDate.contractStartDate.startDate !== null ?
                        selectedDate.contractStartDate.startDate.getFullYear() + '-' + (selectedDate.contractStartDate.startDate.getMonth() + 1) + '-' + selectedDate.contractStartDate.startDate.getDate()
                        : this.state.graphqlStartDateRange.max}`,
                    comparator: selectedDate.contractStartDate.startDate === null ? `${"lte"}` : `${"gte"}`
                },
                {
                    name: `${'contractstartdate'}`,
                    value: `${selectedDate.contractStartDate.endDate !== null ?
                        selectedDate.contractStartDate.endDate.getFullYear() + '-' + (selectedDate.contractStartDate.endDate.getMonth() + 1) + '-' + selectedDate.contractStartDate.endDate.getDate() : this.state.graphqlStartDateRange.min}`,
                    comparator: selectedDate.contractStartDate.endDate === null ? `${"gte"}` : `${"lte"}`
                },
                {
                    name: `${'contractenddate'}`,
                    value: `${selectedDate.contractEndDate.startDate !== null ?
                        selectedDate.contractEndDate.startDate.getFullYear() + '-' + (selectedDate.contractEndDate.startDate.getMonth() + 1) + '-' + selectedDate.contractEndDate.startDate.getDate() : this.state.graphqlEndDateRange.max}`,
                    comparator: selectedDate.contractEndDate.startDate === null ? `${"lte"}` : `${"gte"}`
                },
                {
                    name: `${'contractenddate'}`,
                    value: `${selectedDate.contractEndDate.endDate !== null ?
                        selectedDate.contractEndDate.endDate.getFullYear() + '-' + (selectedDate.contractEndDate.endDate.getMonth() + 1) + '-' + selectedDate.contractEndDate.endDate.getDate() : this.state.graphqlEndDateRange.min}`,
                    comparator: selectedDate.contractEndDate.endDate === null ? `${"gte"}` : `${"lte"}`
                }
            ]
        }
        console.log(filtersGraphQLAccountData)
        // updated the filter data to state so we can use to render data on the basis of user filter selection
        //this.state.updatedFilterToGetData = filtersGraphQLAccountData.filters

        // unpromise the data and save the data into local state 
        if (this.state.promise_graphqlISOEDCFilterAccountLevel !== null) {
            this.state.promise_graphqlISOEDCFilterAccountLevel.then(a => {
                if (a !== undefined) {
                    // console.log(a)
                    setPromiseDataAccountData(a)
                }
                AccountDataChart_KeyValuePair_ISO = []
                AccountDataChart_KeyValuePair_EDC = []
            })
        }
        if (this.state.promise_graphqlISOEDCFilterAccountLevel !== null) {
            if (promiseDataAccountData !== undefined) {
                promiseDataAccountData.map(a => {
                    AccountDataChart_KeyValuePair_ISO.push({
                        ISO: a.iso,
                        color: isoColor(a.iso),
                        ISOValue: a.value,
                        TotalAccountISO: a.count
                    })
                    a.edcs.map(b => {
                        if (selected_ISO_Name === a.iso) {
                            AccountDataChart_KeyValuePair_EDC.push({
                                ISO: a.iso,
                                color: isoColor(a.iso),
                                ISOValue: a.value,
                                EDCName: b.edcname,
                                EDCValue: b.value,
                                TotalAccountEDC: b.count
                            })
                        }
                        return null
                    })
                    return null
                })
                AccountDataChart_KeyValuePair_ISO.sort((a, b) => b.ISOValue - a.ISOValue)
                AccountDataChart_KeyValuePair_EDC.sort((a, b) => b.EDCValue - a.EDCValue)
                // console.log(AccountDataChart_KeyValuePair_ISO)
                // console.log(AccountDataChart_KeyValuePair_EDC)
            }
        }
        // hide EDC's details if button is clicked on "Back Up To ISO Level"
        const handleClickTotalISO_Back = () => {
            this.setState({
                totalISO_Hide: true,
                selectedISO: null,
            })
        }
        // hide EDC Accounts details if button is clicked on "Back Up To EDC Level"
        const handleClickTotalEDC_Back = () => {
            this.setState({
                totalEDC_Hide: true,
                selectedEDC: null,
            })
        }
        //click on iso to get EDC level details for each ISO
        const onClick_ISO = (props) => {
            const { payload } = props;
            // console.log(payload)
            setSelected_ISO_Name(payload.ISO)
            this.setState({
                totalISO_Hide: false, selectedISO: payload.ISO, totalEDC_Hide: true,
                updatedFilterToGetData: filtersGraphQLAccountData // update the filter value on the basis of user selection
            })
        }
        // click on edc to get account level details 
        const onClick_EDC = (props) => {
            const { payload } = props;
            this.setState({
                totalEDC_Hide: false, selectedEDC: payload.EDCName,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            })
        }
        //sign date
        const handleClick_signDate = event => {
            this.setState({
                isShowCalendar_signDate: true,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            });
            setAnchorEl_signDate(event.currentTarget);
        };
        const handleClose_signDate = () => {
            this.setState({
                isShowCalendar_signDate: false,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            });
            //  this.handleChange(selectedDate.contractSignDate, "Contract Sign Date")
            //  console.log(selectedDate.contractSignDate)
            setAnchorEl_signDate(null);
        };
        //start Date
        const handleClick_startDate = event => {
            this.setState({
                isShowCalendar_startDate: true,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            });
            setAnchorEl_startDate(event.currentTarget);
        };
        const handleClose_startDate = () => {
            this.setState({
                isShowCalendar_startDate: false,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            });
            //this.props.onChange(this.state.dateRanges)
            setAnchorEl_startDate(null);
        };
        //end date
        const handleClick_endDate = event => {
            this.setState({
                isShowCalendar_endDate: true,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            });
            setAnchorEl_endDate(event.currentTarget);
        };
        const handleClose_endDate = () => {
            this.setState({
                isShowCalendar_endDate: false,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            });
            setAnchorEl_endDate(null);
        };
        //handle change option checked box
        const handleChange_CheckedBox = (event) => {
            this.setState({
                checkedBoxContractType: event.target.value,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            })
        };
        /*updatedFilterToGetData: filtersGraphQLAccountData is not include in below method because it was not updated the state on the basis of user selection so we have to degain again the variable which need to pass to query inside static getDerivedStateFromProps component  */
        //handle change option checked box
        const handleChange_ContractStatus = (event) => {
            this.setState({
                checkedBoxContractStatus: event.target.value,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            })
        };
        const handleChange_BrandName = (event) => {
            this.setState({
                checkedBoxBrandName: event.target.value,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            })
        };
        const handleChange_BrokerType = (event) => {
            this.setState({
                checkedBoxBroker: event.target.value,
                updatedFilterToGetData: filtersGraphQLAccountData// update the filter value on the basis of user selection
            })
        };
        //handle margin type selection 
        const handleMarginTypeSelection = event => {
            this.setState({
                margin_type: event.target.value
            })
        }
        // handle dialog open / close for CSV Link download
        const handleClose_CSVFileDialog = () => {
            setOpen(false);
            setCSVLinkNotAvailable(false)
            setPromiseDataCSVLink(undefined)
        };
        // handle download CSV File download 
        const handleCSVDownload_OnClick = () => {
            setOpen(true);

            var filter_CSVFile = API.graphql(graphqlOperation(FileDownloadAccountData,
                {
                    filters: filtersGraphQLAccountData.filters,
                    filetype: "account_summary"
                }))
                .then(({ data: getFileDownload }) => {
                    //console.log(getFileDownload.getFileDownload)
                    return getFileDownload.getFileDownload
                });

            filter_CSVFile.then(a => {
                //console.log(a)
                setPromiseDataCSVLink(a)
            }).catch(a => {
                setCSVLinkNotAvailable(true)
                console.log(a.errors[0].message)
            })
            //console.log(filter_CSVFile)
            // console.log(promiseDataCSVLink)
        }

        const formatDate = (date) => {
            // console.log(date)
            return date !== null ? monthNumber_to_Name(date.getMonth()) + ' ' + date.getDate() + ', ' + date.getFullYear()
                : null;
        }

        // rest filter option to default
        const resetFilterToDefault = () => {
            this.setState({ checkedBoxContractType: [], checkedBoxContractStatus: [], checkedBoxBrandName: [], checkedBoxBroker: [], updatedFilterToGetData: null })
            selectedDate.contractSignDate.startDate = null
            selectedDate.contractSignDate.endDate = null
            selectedDate.contractStartDate.startDate = null
            selectedDate.contractStartDate.endDate = null
            selectedDate.contractEndDate.startDate = null
            selectedDate.contractEndDate.endDate = null
        }
        const open_startDate = Boolean(setAnchorEl_startDate);
        const id_startDate = open_startDate ? 'simple-popover' : undefined;

        const open_signDate = Boolean(setAnchorEl_signDate);
        const id_signDate = open_signDate ? 'simple-popover' : undefined;

        const open_endDate = Boolean(setAnchorEl_endDate);
        const id_endDate = open_endDate ? 'simple-popover' : undefined;

        const CustomToolTipISO = (props) => {
            const { active, payload } = props;
            var data = 0;
            // console.log(payload)
            if (!active || !payload) {
                return null;
            }
            else {
                data = payload[0].payload;
                // console.log(dollarOrMWh)
                // console.log(data)
                return (
                    <>
                        <Card>
                            <CardHeader>
                                <strong style={{ color: data.color }}>
                                    ISO: {data.ISO === null ? data.name : data.ISO}
                                    <br />{this.state.margin_type !== 'unit' ? <>Gross Margin: {aggregateValue(data.ISOValue, true)}</> :
                                        <>Unit Margin: {(data.ISOValue * 1000).toFixed(2) + ' $/MWh'}</>}
                                    <br /> Total Accounts: {data.TotalAccountISO === null ? '--' : formatter(data.TotalAccountISO, 'thousands')}
                                </strong>
                            </CardHeader>
                        </Card>
                    </>
                );
            }
        };
        const CustomToolTipEDC = (props) => {
            const { active, payload } = props;
            var data = 0;
            // console.log(payload)
            if (!active || !payload) {
                return null;
            }
            else {
                data = payload[0].payload;
                // console.log(dollarOrMWh)
                // console.log(data)
                return (
                    <>
                        <Card>
                            <CardHeader>
                                <strong style={{ color: data.color }}>
                                    Edc: {data.EDCName}
                                    <br />{this.state.margin_type !== 'unit' ? <>Gross Margin: {aggregateValue(data.EDCValue, true)}</> :
                                        <>Unit Margin: {(data.EDCValue * 1000).toFixed(2) + ' $/MWh'}</>}
                                    <br /> Total Accounts: {data.TotalAccountEDC === null ? '--' : formatter(data.TotalAccountEDC, 'thousands')}
                                </strong>
                            </CardHeader>
                        </Card>
                    </>
                );
            }
        };
        //  console.log(this.state.checkedBoxContractType)
        // console.log(this.state.checkedBoxContractStatus)
        //  console.log(filtersGraphQLAccountData)
        return (
            <>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <Card elevation={3}>
                            <CardHeader ></CardHeader>
                            <CardBody>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <ColorFormlabel component="legend">Please Select the Type</ColorFormlabel>
                                    <RadioGroup aria-label="gender" name="gender1" value={this.state.margin_type} onChange={handleMarginTypeSelection} style={{ display: 'inline', flexDirection: 'row' }}>
                                        <FormControlLabel value='gross' control={<ColorRadioButton />} label="Gross Margin" />
                                        <FormControlLabel value='unit' control={<ColorRadioButton />} label="Unit Margin" />
                                    </RadioGroup>
                                </FormControl>
                                {/* Contract Sign Date*/}
                                <h6 className={classes.cardTitle}>Sign Date</h6>
                                <TextField
                                    type="text"
                                    label="Start - End"
                                    id="outlined-margin-dense"
                                    //  className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    //readOnly
                                    onClick={handleClick_signDate}
                                    value={
                                        selectedDate.contractSignDate["startDate"] !== null ?
                                            formatDate(selectedDate.contractSignDate["startDate"]) + " - " +
                                            formatDate(selectedDate.contractSignDate["endDate"]) : "Please Select Date"
                                    }
                                />
                                {isShowCalendar_signDate !== false ?
                                    <>
                                        <Popover
                                            id={id_signDate}
                                            open={open_signDate}
                                            anchorEl={anchorEl_signDate}
                                            onClose={handleClose_signDate}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Typography className={classes.typography} component={'span'} variant={'body2'}>
                                                <DateRangePicker
                                                    format="YYYY-MM-DD"
                                                    //showSelectionPreview={true}
                                                    //moveRangeOnFirstSelection={false}
                                                    minDate={new Date(this.state.graphqlSignDateRange['min'])}
                                                    maxDate={new Date(this.state.graphqlSignDateRange['max'])}
                                                    scroll={{ enabled: true }}
                                                    startDatePlaceholder="Start Date"
                                                    endDatePlaceholder="End Date"
                                                    onChange={(range) => {
                                                        // console.log(range)

                                                        //this.handleChange(selectedDate.contractSignDate, "Contract Sign Date")
                                                        //console.log(selectedDate.contractSignDate)
                                                        return setSelectedDate({ ...selectedDate, ...range })
                                                    }}
                                                    months={2}
                                                    editableDateInputs={true}
                                                    ranges={[selectedDate.contractSignDate]}
                                                    direction="horizontal"
                                                />
                                            </Typography>
                                        </Popover>
                                    </>

                                    : null}
                                {/* Contract Start Date*/}
                                <h6 className={classes.cardTitle}>Start Date</h6>
                                <TextField
                                    type="text"
                                    label="Start - End"
                                    id="outlined-margin-dense"
                                    className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    readOnly
                                    style={{ width: "100%" }}
                                    onClick={handleClick_startDate}
                                    value={
                                        selectedDate.contractStartDate["startDate"] !== null ?
                                            formatDate(selectedDate.contractStartDate["startDate"]) + " - " +
                                            formatDate(selectedDate.contractStartDate["endDate"]) : "Please Select Date"
                                    }
                                />
                                {isShowCalendar_startDate !== false ?
                                    <Popover
                                        id={id_startDate}
                                        open={open_startDate}
                                        anchorEl={anchorEl_startDate}
                                        onClose={handleClose_startDate}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Typography className={classes.typography} component={'span'} variant={'body2'}>
                                            <DateRangePicker
                                                format="DD-MM-YYYY"
                                                //showSelectionPreview={true}
                                                //moveRangeOnFirstSelection={false}
                                                minDate={new Date(this.state.graphqlStartDateRange['min'])}
                                                maxDate={new Date(this.state.graphqlStartDateRange['max'])}
                                                scroll={{ enabled: true }}
                                                startDatePlaceholder="Start Date"
                                                endDatePlaceholder="End Date"
                                                onChange={(range) => {
                                                    //  this.handleChange(selectedDate.contractStartDate, "Contract Start Date")
                                                    return setSelectedDate({ ...selectedDate, ...range })
                                                }}
                                                months={2}
                                                editableDateInputs={true}
                                                ranges={[selectedDate.contractStartDate]}
                                                direction="horizontal"
                                            />
                                        </Typography>
                                    </Popover>
                                    : null}
                                {/* Contract End Date*/}
                                <h6 className={classes.cardTitle}>End Date</h6>
                                <TextField
                                    type="text"
                                    label="Start - End"
                                    id="outlined-margin-dense"
                                    className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    readOnly
                                    style={{ width: "100%" }}
                                    onClick={handleClick_endDate}
                                    value={
                                        selectedDate.contractEndDate["startDate"] !== null ?
                                            formatDate(selectedDate.contractEndDate["startDate"]) + " - " +
                                            formatDate(selectedDate.contractEndDate["endDate"]) : "Please Select Date"
                                    }
                                />
                                {isShowCalendar_endDate !== false ?
                                    <Popover
                                        id={id_endDate}
                                        open={open_endDate}
                                        anchorEl={anchorEl_endDate}
                                        onClose={handleClose_endDate}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Typography className={classes.typography} component={'span'} variant={'body2'}>
                                            <DateRangePicker
                                                format="DD-MM-YYYY"
                                                //showSelectionPreview={true}
                                                //moveRangeOnFirstSelection={false}
                                                minDate={new Date(this.state.graphqlEndDateRange['min'])}
                                                maxDate={new Date(this.state.graphqlEndDateRange['max'])}
                                                scroll={{ enabled: true }}
                                                startDatePlaceholder="Start Date"
                                                endDatePlaceholder="End Date"
                                                onChange={(range) => {
                                                    //    console.log(range)
                                                    //                     this.handleChange(selectedDate.contractEndDate, "Contract End Date")
                                                    return setSelectedDate({ ...selectedDate, ...range })
                                                }}
                                                months={2}
                                                editableDateInputs={true}
                                                ranges={[selectedDate.contractEndDate]}
                                                direction="horizontal"
                                            />
                                        </Typography>
                                    </Popover>
                                    : null}
                                {this.state.graphqlContractStatus[0] !== undefined ?
                                    <>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-mutiple-chip-label"><strong>Contract Status</strong></InputLabel>
                                            <Select
                                                labelId="demo-mutiple-chip-label"
                                                id="demo-mutiple-chip"
                                                multiple
                                                value={this.state.checkedBoxContractStatus}
                                                onChange={handleChange_ContractStatus}
                                                input={<Input id="select-multiple-chip" />}
                                                renderValue={(selected) => (
                                                    <strong><div className={classes.chips} style={{ fontSize: 19 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} className={classes.chip} fontSize={200} />
                                                        ))}
                                                    </div></strong>
                                                )}
                                            //   MenuProps={MenuProps}
                                            >
                                                {this.state.graphqlContractStatus.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        <ColorCheckBox checked={this.state.checkedBoxContractStatus.indexOf(option) > -1} />
                                                        <ListItemText primary={option} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </> : null}
                                {this.state.graphqlBrandName[0] !== undefined ?
                                    <>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-mutiple-chip-label"><strong>Brand Name</strong></InputLabel>
                                            <Select
                                                labelId="demo-mutiple-chip-label"
                                                id="demo-mutiple-chip"
                                                multiple
                                                value={this.state.checkedBoxBrandName}
                                                onChange={handleChange_BrandName}
                                                input={<Input id="select-multiple-chip" />}
                                                renderValue={(selected) => (
                                                    <strong><div className={classes.chips} style={{ fontSize: 19 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} className={classes.chip} fontSize={200} />
                                                        ))}
                                                    </div></strong>
                                                )}
                                            //   MenuProps={MenuProps}
                                            >
                                                {this.state.graphqlBrandName.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        <ColorCheckBox checked={this.state.checkedBoxBrandName.indexOf(option) > -1} />
                                                        <ListItemText primary={option} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </> : null}
                                {this.state.graphqlBrokerType[0] !== undefined ?
                                    <>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-mutiple-chip-label"><strong>Broker</strong></InputLabel>
                                            <Select
                                                labelId="demo-mutiple-chip-label"
                                                id="demo-mutiple-chip"
                                                multiple
                                                value={this.state.checkedBoxBroker}
                                                onChange={handleChange_BrokerType}
                                                input={<Input id="select-multiple-chip" />}
                                                renderValue={(selected) => (
                                                    <strong><div className={classes.chips} style={{ fontSize: 19 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} className={classes.chip} fontSize={200} />
                                                        ))}
                                                    </div></strong>
                                                )}
                                            //   MenuProps={MenuProps}
                                            >
                                                {this.state.graphqlBrokerType.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        <ColorCheckBox checked={this.state.checkedBoxBroker.indexOf(option) > -1} />
                                                        <ListItemText primary={option} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </> : null}
                                {this.state.graphqlOptionList[0] !== undefined ?
                                    <>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-mutiple-chip-label"><strong>Contract Types</strong></InputLabel>
                                            <Select
                                                labelId="demo-mutiple-chip-label"
                                                id="demo-mutiple-chip"
                                                multiple
                                                value={this.state.checkedBoxContractType}
                                                onChange={handleChange_CheckedBox}
                                                input={<Input id="select-multiple-chip" />}
                                                renderValue={(selected) => (
                                                    <strong><div className={classes.chips} style={{ fontSize: 19 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} className={classes.chip} fontSize={200} />
                                                        ))}
                                                    </div></strong>
                                                )}
                                            //   MenuProps={MenuProps}
                                            >
                                                {this.state.graphqlOptionList.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        <ColorCheckBox checked={this.state.checkedBoxContractType.indexOf(option) > -1} />
                                                        <ListItemText primary={option} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <ColorButton variant="contained" color="primary" className={classes.margin}
                                            onClick={resetFilterToDefault}>
                                            Reset Filters</ColorButton><br></br><br></br>
                                            *By default all filters are selected and dates are on the basis of min-max
                                    </>
                                    : null}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                        <Card elevation={3}>
                            <CardHeader >
                            </CardHeader>
                            <CardBody>
                                <ColorButtonAlignRight variant="outlined" color="primary" onClick={handleCSVDownload_OnClick}>
                                    Generate CSV File      </ColorButtonAlignRight>
                                <Dialog onClose={handleClose_CSVFileDialog} aria-labelledby="customized-dialog-title" open={open}>
                                    {promiseDataCSVLink === undefined ?
                                        <>
                                            <DialogTitle id="customized-dialog-title" onClose={handleClose_CSVFileDialog}>
                                                File Download      </DialogTitle>
                                            <DialogContent dividers>
                                                {CSVLinkNotAvailable === false ?
                                                    <>
                                                        <Alert severity="info">File generating, please wait!</Alert><br></br>
                                                        <LinearProgress />
                                                    </>
                                                    :
                                                    <>
                                                        <Alert severity="error">Problem with file download</Alert>
                                                    </>
                                                }
                                            </DialogContent>
                                            <DialogActions></DialogActions>
                                        </>
                                        :
                                        <>
                                            <DialogTitle id="customized-dialog-title" onClose={handleClose_CSVFileDialog}>
                                                File Download        </DialogTitle>
                                            <DialogContent dividers>
                                                <Typography gutterBottom>
                                                    <Alert severity="success">Please click button to download!</Alert><br></br>
                                                </Typography>
                                                <ColorButtonAlignRight variant="contained" color="primary" href={promiseDataCSVLink} target="_blank">
                                                    Download CSV</ColorButtonAlignRight>
                                            </DialogContent>
                                            <DialogActions></DialogActions>
                                        </>
                                    }
                                </Dialog>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {AccountDataChart_KeyValuePair_ISO[0] !== undefined ?
                                    <>
                                        {this.state.totalISO_Hide === true ?
                                            <>
                                                <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                                    onClick={onClick_DownloadIamge}>
                                                    Download Graph</ColorButtonAlignRight><br></br><br></br>
                            *Please click on any ISO to view it's EDC's details
                                <div id="my-node">
                                                    <ResponsiveContainer width="100%" aspect={
                                                        AccountDataChart_KeyValuePair_ISO.length < 10 ? 2 / 0.5 :
                                                            AccountDataChart_KeyValuePair_ISO.length <= 10 && AccountDataChart_KeyValuePair_ISO.length >= 30 ? 2 / 1 :
                                                                AccountDataChart_KeyValuePair_ISO.length > 30 && AccountDataChart_KeyValuePair_ISO.length <= 60 ? 2 / 1.5 : 2 / 2}>
                                                        <BarChart layout="vertical" data={AccountDataChart_KeyValuePair_ISO} margin={{ top: 0, right: 30, bottom: 20, left: 20 }} >
                                                            <CartesianGrid stroke='#f5f5f5' />
                                                            <XAxis tick={this.state.margin_type !== 'unit' ? <CustomXAxisDollarVertical /> : <CustomXAxisDollarMWhVertical />} type="number" />
                                                            <YAxis dataKey="ISO" tick={<CustomizedAxisTickVertical />} type="category" />
                                                            <Tooltip content={<CustomToolTipISO />} />
                                                            <Bar type="monotone" dataKey="ISOValue" barSize={20} onClick={onClick_ISO}>
                                                                {
                                                                    AccountDataChart_KeyValuePair_ISO.map((entry, index) => {
                                                                        return <Cell key={`cell-${index}`} fill={entry.color} />
                                                                    })
                                                                }
                                                            </Bar>
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            </> : this.state.totalISO_Hide === false && this.state.totalEDC_Hide === true ?
                                                <>
                                                    <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                                        onClick={onClick_DownloadIamge}>
                                                        Download Graph</ColorButtonAlignRight>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <ColorButton variant="contained" color="primary" className={classes.margin}
                                                        onClick={handleClickTotalISO_Back}>
                                                        Back up to ISO Level</ColorButton><br></br>
                                                    {AccountDataChart_KeyValuePair_EDC[0] !== undefined ?
                                                        <><br></br>*Please click on any EDC to view it's Account's Level Details details
                                            <div id="my-node">
                                                                < ResponsiveContainer width="100%" height="100%" aspect={
                                                                    AccountDataChart_KeyValuePair_EDC.length < 10 ? 2 / 0.5 :
                                                                        AccountDataChart_KeyValuePair_EDC.length <= 10 && AccountDataChart_KeyValuePair_EDC.length >= 30 ? 2 / 1 :
                                                                            AccountDataChart_KeyValuePair_EDC.length > 30 && AccountDataChart_KeyValuePair_EDC.length <= 60 ? 2 / 1.5 : 2 / 2}>
                                                                    <BarChart layout="vertical" data={AccountDataChart_KeyValuePair_EDC} margin={{ top: 5, right: 30, bottom: 20, left: 40 }}>
                                                                        <CartesianGrid stroke='#f5f5f5' />
                                                                        <XAxis tick={this.state.margin_type !== 'unit' ? <CustomXAxisDollarVertical /> : <CustomXAxisDollarMWhVertical />} type="number" />
                                                                        <YAxis dataKey="EDCName" tick={<CustomizedAxisTickVertical />} type="category" />
                                                                        <Tooltip content={<CustomToolTipEDC />} />
                                                                        <Bar type="monotone" dataKey="EDCValue" barSize={20} onClick={onClick_EDC}>
                                                                            {
                                                                                AccountDataChart_KeyValuePair_EDC.map((entry, index) => {
                                                                                    return <Cell key={`cell-${index}`} fill={entry.color} />
                                                                                })
                                                                            }
                                                                        </Bar>
                                                                    </BarChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </>
                                                        : <LinearProgress />}
                                                </>
                                                : this.state.totalEDC_Hide === false ?
                                                    <>
                                                        { /*<ColorButton variant="contained" color="primary" className={classes.margin}
                                            onClick={this.handleClickTotalISO_Back}>
                                            Back up to ISO Level</ColorButton> &nbsp;*/}
                                                        <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                                            onClick={onClick_DownloadIamge}>
                                                            Download Graph</ColorButtonAlignRight>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <ColorButton variant="contained" color="primary" className={classes.margin}
                                                            onClick={handleClickTotalEDC_Back}>
                                                            Back up to EDC Level</ColorButton><br></br><br></br>
                                                        <div id="my-node">
                                                            <CustomerAccountLevelDetails
                                                                filtersGraphQLAccountData={filtersGraphQLAccountData}
                                                                selectedISOFilter={this.state.selectedISO}
                                                                selectedEDCFilter={this.state.selectedEDC}
                                                                marginType={this.state.margin_type}
                                                            />

                                                        </div>
                                                    </>
                                                    : <LinearProgress />}
                                    </> : <LinearProgress />}

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card elevation={3}>

                            <CardHeader><h4 className={classes.cardIconTitle}>Account Detail Table</h4></CardHeader>
                            <CardBody>
                                <CustomerDetailsTable
                                    filtersGraphQLAccountData={filtersGraphQLAccountData}
                                    selectedISOFilter={this.state.selectedISO !== null ? this.state.selectedISO : this.state.graphqlISOList}
                                    selectedEDCFilter={this.state.selectedEDC !== null ? this.state.selectedEDC : this.state.graphqlEDCName}
                                    marginType={this.state.margin_type}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
    render() {
        return (<this.AccountDataContainerUI />)
    }
}
export default AccountDataContainer;