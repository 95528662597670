import React, { Component } from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core/styles';
// const useStyles = makeStyles(styles);
const useStyles = makeStyles(() => ({
    textField: {
        color: 'white',
        // width: "auto",
        textAlign: "right",
        '&:before': {
            borderBottom: "none"
        },
        '&:after': {
            borderBottom: "none"
        },
        icon: {
            fill: 'white',
        },
    },

}));

class SelectedDate extends Component {
    
    SelectedDateUI = () => {
        // get the default date from localstorage
        var getDefaultDate = String(window.localStorage.getItem(`defaultDate`))
        const [selectedDate, setSelectedDate] = React.useState(getDefaultDate);
        const classes = useStyles();

        // get the list of available user from local storage
        var availableUserDate = window.localStorage.getItem(`userDates`)
        availableUserDate = availableUserDate ? availableUserDate.split(',') : []
       // console.log(availableUserDate)

        // update the date into the local storage on the basis of user selection
        const handleSelectedClient = (event) => {
            const selected = event.target.value;
           // console.log(selected)
            setSelectedDate(selected);
            window.localStorage.setItem(`defaultDate`, selected)
            window.location.reload();
        }
        //console.log(selectedDate)
        // var test = ['2020-02-18', '2020', '2025']
        return (
            <div>
                {availableUserDate[0] !== undefined ?
                    <Select
                        inputProps={{
                            name: "selectedDate",
                            id: "simple-select",
                            classes: {
                                icon: classes.textField,
                            },
                        }}
                        className={classes.textField}
                        variant="standard"
                        value={selectedDate}
                        onChange={handleSelectedClient}
                    >
                        {availableUserDate.map(a => (
                            <MenuItem key={a} value={a} >
                                {a}
                            </MenuItem >
                        ))}
                    </Select>
                    : <Select
                        inputProps={{
                            name: "selectedDate",
                            id: "simple-select",
                            classes: {
                                icon: classes.textField,
                            },
                        }}
                        className={classes.textField}
                        variant="standard"
                        value="N/A"
                    >
                        <MenuItem key={null} value={null} selected>
                            N/A
                            </MenuItem >
                    </Select>
                }
            </div>

        )
    }
    render() {
        return (<this.SelectedDateUI props={this.props} />)
    }
}
export default SelectedDate;