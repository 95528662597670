import React, { Component } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import { MaterialUITable_Graph, AggregatedTable_With_Value } from '../ReactTableReUseAbleComponent/MaterialUITable'
//import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { aggregateValue } from '../../elements/ReactTableReUseAbleComponent/MaterialUITable'
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchDataInTable, {
    SelectColumnFilter, DefaultColumnFilter
} from '../../elements/ReactTableReUseAbleComponent/SearchDataInTable'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ColorRadioButton, ColorFormlabel } from '../../utils/materialUI_Reuseable_Comp'
import { getMonths, capitalize } from '../../utils/helper';

const setMonths = getMonths();
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));

class EDCbyVolumeMargin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: '0',
            selectedYearId: 0,
            years: this.props.yearList,
            months: setMonths,
        };
    }

    handleYearSelect(y) {
        this.setState({
            selectedYearId: y.id
        })
    };
    EDCbyVolumeMarginUI = () => {
        // console.log(this.props.getGraphQL_ForwardMargin_Format_EDC_Table)
        const classes = useStyles();
        const [YearMonthView, setYearMonthView] = React.useState('0');
        const [selectedYear, setSelectedYear] = React.useState(['2020']); // hooks to handle iso selected state

        var DropDownYearList = [];
        var SearchDataTable = new SearchDataInTable();
        //let yearListISO = [{ year: '2020', id: 0 }, { year: '2021', id: 1 }, { year: '2022', id: 2 }, { year: '2023', id: 3 }, { year: '2024', id: 4 }]
        const inputLabel = React.useRef(null);
        const [labelWidth, setLabelWidth] = React.useState(0);
        React.useEffect(() => {
            setLabelWidth(100);
        }, []);
        const handleYearMonthView = event => {
            this.setState({ view: event.target.value })
            //console.log(event.target.value)
            setYearMonthView(event.target.value);
        };
        const handleSelectedYear = event => {
            this.setState({ selectedYear: event.target.value })
            this.state.years.map(a => {
                if (a.year === event.target.value) {
                    this.setState({ selectedYearId: a.id })
                }
                return a
            })
            setSelectedYear(event.target.value);
        };
        DropDownYearList = this.state.years.map(a => a.year)
        const EDCDataByYear = [
            // get the column and display type and subtype of ISO
            // first column
            {
                Header: ' ',
                id: 'expander',
                sticky: 'left',
                //columns detemine the prototype of table and how the grouping will be perfrom
                columns: [
                    {
                        Header: 'ISO',
                        accessor: 'iso',
                        Filter: SelectColumnFilter,
                        filter: 'includes'
                    },
                    {
                        Header: 'EDC Name',
                        accessor: 'edcName',
                        //Filter: DefaultColumnFilter
                        Filter: SelectColumnFilter,
                        filter: 'includes'
                    },
                    {
                        Header: 'Gross Margin/Volume',
                        accessor: row => {
                            return capitalize(row.type)
                        },
                        Filter: SelectColumnFilter,
                        filter: 'includes'
                    },
                    /*{
                        Header: ' ',
                        accessor: () => {
                            return (
                                <>
                                    <SearchDataTable.OnClickDrawGraphYearly
                                        yearView={true}
                                    />
                                </>
                            )
                        },
                    }*/
                ]
            },
            // display the year (2019....2024 - five year data) of iso and its sub component
            // second column 
            {
                Header: ' ',
                id: 'col2',
                columns: this.state.years.map(key => {
                    key = key.year; // get the value of year and assign to key
                    return {
                        Header: key,
                        //assign the data of row to row using key=year and it will use for aggregate and Aggregated
                        accessor: row => {
                            var yearlyData
                            if (row.values[key] !== undefined) {
                                yearlyData = row.values[key].yearly
                            } else {
                                yearlyData = 0
                            }
                            if (row.type === 'volume') {
                                return aggregateValue(yearlyData, false)
                            } else {
                                return aggregateValue(yearlyData, true)
                            }
                        },
                        //Filter: NumberRangeColumnFilter,
                        //filter: 'between',
                    }
                }),
            },
            //display total of each row of ISO
            // third column
            {
                Header: '',
                id: 'col3',
                sticky: 'right',
                columns: [{
                    Header: 'Total',
                    id: 'ISOYearTotal',
                    accessor: (row) => {
                        //calculate entire row and display the total in last column
                        var calc = this.state.years.reduce((acc, key) => {
                            return parseFloat(acc) + parseFloat(row.values[key.year] !== undefined ? row.values[key.year].yearly : 0.0)
                        }, 0);
                        //console.log(calc);
                        if (row.type === 'volume') {
                            return aggregateValue(calc, false)
                        } else {
                            return aggregateValue(calc, true)
                        }
                    },
                    //calculate child data of column and assign or display its parent
                    //Filter: NumberRangeColumnFilter,
                    //filter: 'between',
                }]
            }];

        const EDCDataByMonth = [
            // get the column and display type and subtype of ISO
            // first column
            {
                Header: ' ',
                id: 'expander',
                sticky: 'left',
                //columns detemine the prototype of table and how the grouping will be perfrom
                columns: [
                    {
                        Header: 'ISO',
                        accessor: 'iso',
                        Filter: SelectColumnFilter,
                        filter: 'includes'
                    },
                    {
                        Header: 'EDC Name',
                        accessor: 'edcName',
                        Filter: DefaultColumnFilter
                    },
                    {
                        Header: 'Gross Margin/Volume',
                        accessor: row => {
                            return capitalize(row.type)
                        },
                        Filter: SelectColumnFilter,
                        filter: 'includes'
                    },
                    /*{
                        Header: ' ',
                        accessor: () => {
                            return (
                                <div>
                                    <SearchDataTable.OnClickDrawGraphYearly
                                        yearView={false}
                                        selectedYear={selectedYear}
                                    />
                                </div>
                            )
                        },
                    }*/
                ]
            },
            // display the year (2019....2024 - five year data) of iso and its sub component
            // second column 
            {
                Header: ' ',
                id: 'col2',
                columns: this.state.months.map(key => {
                    key = key.name; // get the value of month and assign to key
                    return {
                        Header: capitalize(key),
                        //assign the data of row to row using key=year and it will use for aggregate and Aggregated
                        accessor: row => {
                            var monthlyData
                            if (row.values[selectedYear] !== undefined) {
                                if (row.values[selectedYear].monthly[key] !== undefined) {
                                    monthlyData = row.values[selectedYear].monthly[key]
                                } else {
                                    monthlyData = 0
                                    row.values[selectedYear].monthly[key] = 0
                                }
                            } else {
                                monthlyData = 0
                            }
                            if (row.type === 'volume') {
                                return aggregateValue(monthlyData, false)
                            } else {
                                return aggregateValue(monthlyData, true)
                            }
                        },
                        //Filter: NumberRangeColumnFilter,
                        //filter: 'between',
                    }
                }),
            },
            //display total of each row of ISO
            // third column
            {
                Header: '',
                id: 'col3',
                sticky: 'right',
                columns: [{
                    Header: 'Total',
                    id: 'ISOYearTotal',
                    accessor: (row) => {
                        //console.log(row)
                        //calculate entire row and display the total in last column
                        var calc = this.state.months.reduce((acc, key) => {
                            // get the year selected from list to dispaly data
                            //var selectedYear = `${this.props.years.find(y => y.id === 2020).year}`;
                            // extract data from json file to display on UI and return it
                            var monthlyData = row.values[selectedYear] !== undefined ? row.values[selectedYear].monthly[key.name] : 0.0;
                            return parseFloat(acc) + parseFloat(monthlyData)
                        }, 0);
                        //console.log(calc);
                        if (row.type === 'volume') {
                            return aggregateValue(calc, false)
                        } else {
                            return aggregateValue(calc, true)
                        }
                    },
                    //Filter: NumberRangeColumnFilter,
                    //filter: 'between',
                }]
            }
        ];

        if (!this.props.getGraphQL_ForwardMargin_Format_EDC_Table) {
            return <LinearProgress />
        } else {
            return (
                <>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <ColorFormlabel component="legend">Please Select the View</ColorFormlabel>
                        <RadioGroup aria-label="gender" name="gender1" value={YearMonthView} onChange={handleYearMonthView} style={{ display: 'inline', flexDirection: 'row' }}>
                            <FormControlLabel value='0' control={<ColorRadioButton />} label="Annual" />
                            <FormControlLabel value='1' control={<ColorRadioButton />} label="Monthly" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="outlined"
                        className={classes.formControl}
                        style={this.state.view === "0" ?
                            { visibility: 'hidden', cursor: 'pointer' } : null} >
                        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">Select Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            labelWidth={labelWidth}
                            value={selectedYear}
                            onChange={handleSelectedYear}                                    >
                            {DropDownYearList.map(a => (
                                <MenuItem key={a} value={a}>
                                    {a}
                                </MenuItem >
                            ))}
                        </Select>
                    </FormControl>
                    {this.props.getGraphQL_ForwardMargin_Format_EDC_Table != null ?

                        < SearchDataTable.SearchDataInTableUI
                            columns={this.state.view === "0" ? EDCDataByYear : EDCDataByMonth}
                            data={this.props.getGraphQL_ForwardMargin_Format_EDC_Table}
                        />
                        : <LinearProgress />}
                </>
            )
        }
    }
    render() {
        // console.log(this.props.graphQLEDCbyMarginVolumeData)
        // console.log(this.props.grossMarginByAccount)
        return (<this.EDCbyVolumeMarginUI />)
    }
}
export default EDCbyVolumeMargin;