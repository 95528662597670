import React from 'react';
//import { MaterialUITable_Graph, AggregatedTable_With_Value } from '../ReactTableReUseAbleComponent/MaterialUITable'
//import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { aggregateValue } from '../../elements/ReactTableReUseAbleComponent/MaterialUITable'
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Brush, ResponsiveContainer, Cell, Bar, BarChart
} from 'recharts';
import { CustomizedAxisTick, CustomYAxisMWh } from '../../utils/rechartHelper'
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
//import TimelineIcon from '@material-ui/icons/Timeline';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import { isoColor } from '../../utils/helper'
import { onClick_DownloadIamge } from '../../utils/downloadGraphImage'
import { ColorButtonAlignRight } from '../../utils/materialUI_Reuseable_Comp'
import { getISOEDCVolume } from "../../graphQL_Query/AccountDataQuery"
import { API, graphqlOperation } from 'aws-amplify'
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);
class AccountCustomerDetailsGraphTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promise_graphQLCustomerLevelDetails: null,
            selectISOName: this.props.selectISONameGraphTable,
            selectEDCName: this.props.selectEDCNameGraphTable,
        }
    }
    // this is react component in new version of react which renders on the basis of props change 
    static getDerivedStateFromProps(props, state) {
        var response
        // console.log(props.selectEDCNameGraphTable)
        //console.log(props.selectISONameGraphTable)
        //console.log(props.filtersGraphQLData.filters)
        //console.log(state.selectEDCName)
        if (props.selectEDCNameGraphTable !== state.selectEDCName) {
            // if both edcname different render this api
            response = API.graphql(graphqlOperation(getISOEDCVolume,
                {
                    filters: props.filtersGraphQLData.filters,
                    sort_by: `${props.marginType}`
                }))
                .then(({ data: getAccountDataQuery }) => {
                    // console.log(getAccountDataQuery.getAccountData)
                    return getAccountDataQuery.getAccountData
                }).catch(a => {
                    console.log(a.errors[0].message)
                });;
            // console.log(response)
            return { promise_graphQLCustomerLevelDetails: response, AccountDataChart_KeyValuePair: [] };
        } else if (props.selectEDCNameGraphTable === state.selectEDCName) {
            //if both edcname same then render this api
            response = API.graphql(graphqlOperation(getISOEDCVolume,
                {
                    filters: props.filtersGraphQLData.filters,
                    sort_by: `${props.marginType}`
                }))
                .then(({ data: getAccountDataQuery }) => {
                    // console.log(getAccountDataQuery.getAccountData)
                    return getAccountDataQuery.getAccountData
                }).catch(a => {
                    console.log(a.errors[0].message)
                });;
            // console.log(response)
            return { promise_graphQLCustomerLevelDetails: response, AccountDataChart_KeyValuePair: [] };
        }
        return null
    }

    AccountCustomerDetailsGraphTableUI = () => {
        const classes = useStyles();
        var [promiseData, setPromiseData] = React.useState()
        var AccountDataChart_KeyValuePair_Volume = []
        if (this.state.promise_graphQLCustomerLevelDetails !== null) {
            this.state.promise_graphQLCustomerLevelDetails.then(a => {
                if (a !== undefined) {
                    //    console.log(a)
                    setPromiseData(a)
                }
                AccountDataChart_KeyValuePair_Volume = []
            })
        }
        // console.log(promiseData)
        if (this.state.promise_graphQLCustomerLevelDetails !== null) {
            AccountDataChart_KeyValuePair_Volume = []
            if (promiseData !== undefined) {
                promiseData.map(a => {
                    //  console.log(a)
                    a.edcs.map(b => {
                        //    console.log(b)
                        b.accounts.map(c => {
                            //   console.log(c)
                            AccountDataChart_KeyValuePair_Volume.push({
                                ISO: a.iso,
                                EDCName: b.edcname,
                                color: isoColor(a.iso),
                                AccountNo: c.account,
                                GrossMargin: c.grossmargin,
                                UnitMargin: (c.grossmargin / c.contractvolume) * 1000,
                                Volume: c.contractvolume,
                                ContractType: c.contracttype,
                                ContractSignDate: c.contractsigneddate,
                                ContractStartDate: c.contractstartdate,
                                ContractEndDate: c.contractenddate
                            })
                            return null
                        })
                        return null
                    })
                    return null
                })
            }
            AccountDataChart_KeyValuePair_Volume.sort((a, b) => b.Volume - a.Volume)
            //console.log(AccountDataChart_KeyValuePair_Volume)
        }

        const CustomToolTip = (props) => {
            const { active, payload } = props;
            var data = 0;
            // console.log(payload)
            if (!active || !payload) {
                return null;
            }
            else {
                data = payload[0].payload;
                // console.log(dollarOrMWh)
                // console.log(data)
                const subStyle = {
                    color: 'red'
                };
                return (
                    <>
                        <Card>
                            <CardHeader>
                                <strong style={{ color: data.color }}>
                                    Account No: {data.AccountNo}
                                    <br />Gross Margin: {aggregateValue(data.GrossMargin, true)}
                                    <br />Unit Margin: {data.UnitMargin.toFixed(2) < 0 ? <span style={subStyle}>({data.UnitMargin.toFixed(2).replace("-", "") + ' $/MWh'})</span> : <span>{data.UnitMargin.toFixed(2) + ' $/MWh'}</span>}
                                    <br /> Volume: {aggregateValue(data.Volume, false)}
                                    <br /> Contract Type: {data.ContractType}
                                    <br /> Contract Sign Date: {data.ContractSignDate}
                                    <br /> Contract Start Date: {data.ContractStartDate}
                                    <br /> Contract End Date: {data.ContractEndDate}
                                </strong>
                            </CardHeader>
                        </Card>
                    </>
                );
            }
        };
        return (
            <>
                {AccountDataChart_KeyValuePair_Volume[AccountDataChart_KeyValuePair_Volume.length - 1] !== undefined ?
                    <>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card elevation={3}>
                                    <CardHeader >
                                        <h4 className={classes.cardIconTitle}>Volume &nbsp;	&nbsp;
                                            <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                                onClick={onClick_DownloadIamge}>
                                                Download Graph</ColorButtonAlignRight></h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div id="my-node">
                                            <ResponsiveContainer height={500} width="100%">
                                                <BarChart data={AccountDataChart_KeyValuePair_Volume}
                                                    margin={{ top: 10, right: 5, left: 35, bottom: 0 }}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="AccountNo" height={60} tick={<CustomizedAxisTick />} />
                                                    <YAxis tick={<CustomYAxisMWh />} />
                                                    <Tooltip content={<CustomToolTip />} />
                                                    <Bar type="monotone" dataKey="Volume">
                                                        {
                                                            AccountDataChart_KeyValuePair_Volume.map((entry, index) => {
                                                                return <Cell key={`cell-${index}`} fill={entry.color} stroke={entry.color} />
                                                            })
                                                        }
                                                    </Bar>
                                                    <Brush height={100} endIndex={AccountDataChart_KeyValuePair_Volume.length > 100 ? 100 : 0} >
                                                        <BarChart>
                                                            <Bar type="monotone" dataKey="Volume" barSize={20}>
                                                                {
                                                                    AccountDataChart_KeyValuePair_Volume.map((entry, index) => {
                                                                        return <Cell key={`cell-${index}`} fill={entry.color} />
                                                                    })
                                                                }
                                                            </Bar>
                                                        </BarChart>
                                                    </Brush>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </>
                    :
                    <LinearProgress />
                }
            </>
        )
    }
    render() {
        return (
            <>
                <this.AccountCustomerDetailsGraphTableUI />
            </>
        )
    }
}
export default AccountCustomerDetailsGraphTable;