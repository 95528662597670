var selectedClient = window.localStorage.getItem(`defaultUser`);
var selectedDate = window.localStorage.getItem(`defaultDate`)

export const ISO_ForwardGrossMarginQuery = `query getMonthlyData {
	getMonthlyData(client:"${selectedClient}", id: "${selectedDate}") {
		iso
    	type
    	values {
        year
        values {
          month
          value
        }
      }
    }
}`;

export const ISOEDC_ForwardGrossMarginDataQuery = `query getMonthlyData {
	getMonthlyData(client:"${selectedClient}", id: "${selectedDate}") {
		iso
    	type
  	edcs {
      name
      values {
        year
        values {
          month
          value
        }
      }
    }
    }
}`;
