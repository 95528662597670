
import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
const GreenCheckbox = withStyles({
    root: {
      color: "#156099",
      '&$checked': {
        color: "#156099",
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);
class FilterControlOptionsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkedItems: new Map(),
        }
        this.props.options.forEach((option) =>
            this.state.checkedItems.set(option.label, true))


        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e, name) {
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(name, isChecked) }),
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.checkedItems);
                }
            })
    }
    render() {
        return (
            <>
                {this.props.options.map((option) => (
                    <>
                        <FormControlLabel
                            control={
                                <GreenCheckbox
                                    checked={this.state.checkedItems.get(option.label)}
                                    onChange={(e) => this.handleChange(e, option.label)}
                                    value="checkedB"
                                />
                            }
                            //key={option.label}
                            label={option.label}
                        />
                    </>
                ))}
            </>
        )
    }
}

export default FilterControlOptionsList;