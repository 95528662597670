import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  KeyboardDatePicker
} from '@material-ui/pickers';

export const ColorButtonSubmit = withStyles(theme => ({
  root: {
    padding: "5px 5px 5px 5px",
    color: theme.palette.getContrastText("#156099"),
    backgroundColor: "#156099",
    '&:hover': {
      backgroundColor: "#156099",
    },
    width: 200
  },
}))(props => <Button {...props} />);

export const ColorButtonBack = withStyles(theme => ({
  root: {
    padding: "5px 5px 5px 5px",
    color: theme.palette.getContrastText("#156099"),
    backgroundColor: "#156099",
    '&:hover': {
      backgroundColor: "#156099",
    },
    width: 200
  },
}))(props => <Button {...props} />);

export const ColorButton = withStyles(theme => ({
  root: {
    padding: "5px 5px 5px 5px",
    color: theme.palette.getContrastText("#156099"),
    backgroundColor: "#156099",
    '&:hover': {
      backgroundColor: "#156099",
    },
  },
}))(props => <Button {...props} />);

export const ColorButtonAlignRight = withStyles(theme => ({
  root: {
    padding: "5px 5px 5px 5px",
    color: theme.palette.getContrastText("#1e8a0e"),
    backgroundColor: "#1e8a0e",
    '&:hover': {
      backgroundColor: "#1e8a0e",
    },
  },
}))(props => <Button {...props} />);
export const ColorRadioButton = withStyles({
  root: {
    color: "#156099",
    '&$checked': {
      color: "#156099",
    },
  },
  checked: {},
})(props => <Radio {...props} />);

export const ColorFormlabel = withStyles({
  root: {
    color: "#156099",
    '&$checked': {
      color: "#156099",
    },
  },
  //checked: {},
})(props => <FormLabel  {...props} />);

export const ColorKeyboardDatePicker = withStyles({
  root: {
    color: "#156099",
    '&$checked': {
      color: "#156099",
    },
  },
  //checked: {},
})(props => <KeyboardDatePicker {...props} />);

export const ColorCheckBox = withStyles({
  root: {
    color: "#156099",
    '&$checked': {
      color: "#156099",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);