import React, { Component } from 'react';
import _ from 'lodash';
import { capitalize } from '../../utils/helper';
import { MaterialUITableGraph, AggregatedTable_With_Value_IncomeByPorfolio } from '../ReactTableReUseAbleComponent/MaterialUITable'
import LinearProgress from '@material-ui/core/LinearProgress';

class IncomeByPortfolio extends Component {

    componentDidMount() { }
    IncomeByPortfolioUI = () => {
        const { monthList, view, IncomeByPortfoliaData, yearList, GrossUnitView } = this.props;
        //console.log(IncomeByPortfoliaData)
        //display by year
        var MaterialTableGraph = new MaterialUITableGraph();
        const portfolioByYear = [
            // get the column and display type and subtype of ISO
            // first column
            {
                Header: ' ',
                id: 'expander',
                //sticky: 'left',
                columns: [
                    {
                        Header: 'Portfolio',
                        accessor: 'type',

                    },
                    /*{
                        Header: 'Visualize',
                        aggregate: () => {
                            return (
                                <>
                                    <MaterialTableGraph.OnClickDrawGraphYearly
                                        yearView={true}
                                    />
                                </>
                            )
                        },
                    }*/
                ]
            },
            // display the year (2019....2024 - five year data) of iso and its sub component
            // second column 
            {
                Header: ' ',
                id: 'col2',
                columns: yearList.map(key => {
                    key = key.year; // get the value of year and assign to key
                    return {
                        Header: key,
                        width: 100,
                        id: key,
                        //assign the data of row to row using key=year and it will use for aggregate and Aggregated
                        accessor: row => {
                            //console.log(row.values[key])
                            if (row.values[key] !== undefined) {
                                return row.values[key] !== undefined ? row.values[key].yearly : 0.0
                            } else {
                                return 0
                            }
                        },
                        //calculate child data of column and assign or display its parent
                        aggregate: values => _.sum(values),
                        Aggregated: row => AggregatedTable_With_Value_IncomeByPorfolio(row, key, GrossUnitView),
                    }
                }),
            },
            //display total of each row of ISO
            // third column
            {
                Header: '',
                id: 'col3',
                sticky: 'right',
                columns: [{
                    Header: 'Total',
                    id: 'ISOYearTotal',
                    width: 100,
                    accessor: (row) => {
                        //calculate entire row and display the total in last column
                        var calc = yearList.reduce((acc, key) => {
                            return parseFloat(acc) + parseFloat(row.values[key.year] !== undefined ? row.values[key.year].yearly : 0.0)
                        }, 0);
                        //console.log(calc);
                        return calc;
                    },
                    //calculate child data of column and assign or display its parent
                    aggregate: values => _.sum(values),
                    Aggregated: row => AggregatedTable_With_Value_IncomeByPorfolio(row, 'ISOYearTotal', GrossUnitView),
                }]

            }
        ];

        // display by month
        const portfolioByMonth = [
            // get the column and display type and subtype of ISO
            // first column
            {
                Header: ' ',
                id: 'expander',
                //sticky: 'left',
                columns: [
                    {
                        Header: 'Portfolio',
                        accessor: 'type',
                        width: 100,
                    },
                    /*{
                        Header: 'Visualize',
                        aggregate: () => {
                            return (
                                <div>
                                    <MaterialTableGraph.OnClickDrawGraphYearly
                                        yearView={false}
                                        selectedYear={this.props.selectedYear}
                                    />
                                </div>
                            )
                        },
                    }*/
                ]
            },
            // display the year (2019....2024 - five year data) of iso and its sub component
            // second column 
            {
                Header: ' ',
                id: 'col2',
                columns: monthList.map(key => {
                    key = key.name; // get the value of month and assign to key
                    return {
                        Header: capitalize(key),
                        width: 100,
                        //assign the data of row to row using key=year and it will use for aggregate and Aggregated
                        accessor: row => {
                            // get the year selected from list to dispaly data
                            var selectedYear = `${this.props.yearList.find(y => y.id === this.props.year).year}`;
                            //console.log(row.values[selectedYear].monthly)
                            //console.log(row.values[selectedYear] !== undefined ? row.values[selectedYear].monthly[key] : 0.0)
                            // extract data from json file to display on UI and return it
                            if (row.values[selectedYear] !== undefined) {
                                if (row.values[selectedYear].monthly !== undefined) {
                                    var monthlyData = row.values[selectedYear].monthly[key] !== undefined ? row.values[selectedYear].monthly[key] : 0.0
                                    return monthlyData

                                } else {
                                    return 0
                                }

                            } else {
                                return 0
                            }
                        },
                        //calculate child data of column and assign or display its parent
                        aggregate: values => _.sum(values),
                        Aggregated: row => AggregatedTable_With_Value_IncomeByPorfolio(row, capitalize(key), GrossUnitView),
                    }
                }),

            },
            //display total of each row of ISO
            // third column
            {
                Header: '',
                id: 'col3',
                sticky: 'right',
                columns: [{
                    Header: 'Total',
                    id: 'ISOYearTotal',
                    width: 100,
                    accessor: (row) => {
                        //console.log(row)
                        //calculate entire row and display the total in last column
                        var calc = monthList.reduce((acc, key) => {
                            var selectedYear = `${this.props.yearList.find(y => y.id === this.props.year).year}`;
                            if (row.values[selectedYear] !== undefined) {
                                if (row.values[selectedYear].monthly !== undefined) {
                                    // get the year selected from list to dispaly data
                                    // extract data from json file to display on UI and return it
                                    var monthlyData = row.values[selectedYear].monthly[key.name] !== undefined ? row.values[selectedYear].monthly[key.name] : 0.0;
                                    return parseFloat(acc) + parseFloat(monthlyData)

                                } else {
                                    return 0
                                }

                            } else {
                                return 0
                            }

                        }, 0);
                        //console.log(calc);
                        return calc;
                    },
                    //calculate child data of column and assign or display its parent
                    aggregate: values => _.sum(values),
                    Aggregated: row => AggregatedTable_With_Value_IncomeByPorfolio(row, 'ISOYearTotal', GrossUnitView),
                }]
            }
        ];
        return (
            <>
                {IncomeByPortfoliaData !== null ?
                    <MaterialTableGraph.MaterialUITable_With_Graph
                        data={IncomeByPortfoliaData}
                        columns={view === "0" ? portfolioByYear : portfolioByMonth}
                        columGrouping={["type"]}
                        //rowSorting={["Total Revenue", "Total Cost", "Contract Margin", "Hedge MTM", "Volume"]}
                        listYear={yearList}
                        listMonth={monthList}
                        yearMonthView={view}
                        hideExpandColumn={true}
                    />
                    : <LinearProgress />}
            </>
        )
    }
    render() {
        return (
            <>
                <this.IncomeByPortfolioUI />
            </>
        )
    }
}
export default IncomeByPortfolio;